import Rule from "../Rule";

export default class TelRule extends Rule {
  process() {
    const emailReg = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

    return emailReg.test(this.value);
  }

  // eslint-disable-next-line class-methods-use-this
  getMessage(name: string): string {
    return `${name} is not a valid phone number`;
  }
}
