/* eslint-disable import/no-named-as-default */
import { configureStore } from "@reduxjs/toolkit";
import editModeReducer from "./slices/editModeSlice";
import editPageReducer from "./slices/editPageSlice";
import pageEditorReducer from "./slices/pageEditorSlice";
import fieldSlice from "./slices/fieldSlice";

export const store = configureStore({
  reducer: {
    editMode: editModeReducer,
    editPageSlice: editPageReducer,
    pageEditorSlice: pageEditorReducer,
    fieldSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
