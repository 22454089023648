import { InformationCircleIcon } from "@heroicons/react/20/solid";

interface Props {
  id: string;
  fieldtype: string;
  error?: string;
  value: string;
  onChange: (id: string, name: string, value: string, rules: any[], label: string, fieldtype: string) => void;
  isRequired?: boolean;
  type?: string;
  rules?: any[];
  disabled: boolean;
  label: string;
}

export default function EmailField({
  id,
  fieldtype,
  isRequired,
  type = "text",
  rules = [],
  error,
  onChange,
  disabled,
  label,
  value,
}: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value: inputValue } = event.target;
    onChange(id, name, inputValue, rules, label, fieldtype);
  };

  fieldtype = "email";
  rules = [...rules, "email"];
  if (isRequired) {
    rules = [...rules, "required"];
  }

  return (
    <div className="bu-relative bu-py-2">
      <input
        id={id}
        name={id}
        type="email"
        value={value}
        required={isRequired}
        onChange={handleChange}
        className={`bu-w-full bu-border-b bu-py-1 focus:bu-outline-none focus:bu-border-b-2 bu-bg-background bu-text-accent bu-transition-colors bu-peer ${
          error ? "bu-border-red bu-bg-red/5" : ""
        }`}
        aria-required={isRequired}
        data-cy={label}
        aria-invalid={!!error}
        aria-describedby={error ? `${id}-error` : undefined}
        disabled={disabled}
      />
      {type !== "hidden" && (
        <label
          htmlFor={id}
          className={`bu-absolute bu-left-1 bu-transition-all bu-text-foreground bu-cursor-text ${
            value ? "bu-text-xs bu--top-4" : "bu-top-2"
          } peer-focus:bu-text-xs peer-focus:bu--top-4 peer-focus:bu-text-foreground`}
        >
          {label} {isRequired && <span className="bu-text-red">*</span>}
        </label>
      )}
      {error && (
        <div id={`${id}-error`} className="bu-capitalize-first bu-text-red bu-flex bu-gap-1">
          <InformationCircleIcon width={16} />
          {error}
        </div>
      )}
    </div>
  );
}
