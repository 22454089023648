interface Option {
  key: string;
  value: string;
}
interface Props {
  id: string;
  fieldtype: string;
  options: Option[];
  onChange: (id: string, name: string, value: string, rules: any[], label: string, fieldtype: string) => void;
  isRequired?: boolean;
  rules?: any[];
  error?: string;
  label: string;
  disabled: boolean;
  selectedValues: string;
}
export default function CheckBoxes({
  id,
  fieldtype,
  options,
  onChange,
  isRequired,
  rules = [],
  error,
  label,
  disabled,
  selectedValues,
}: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    let updatedSelectedValues;

    if (checked) {
      updatedSelectedValues = selectedValues ? `${selectedValues},${event.target.value}` : event.target.value;
    } else {
      const valuesArray = selectedValues.split(",");
      updatedSelectedValues = valuesArray.filter((value) => value !== event.target.value).join(", ");
    }

    onChange(id, name, updatedSelectedValues, rules, label, fieldtype);
  };

  fieldtype = "Checkbox";

  if (isRequired) {
    rules = [...rules, "required"];
  }

  return (
    <div className="bu-flex bu-flex-col">
      <label htmlFor={id} className="bu-m-2 bu-text-foreground">
        {label} {isRequired && <span className="bu-text-red">*</span>}
      </label>
      {options.map((option) => (
        <label key={option.key} htmlFor={`${id}-${option.key}`} className="bu-flex bu-items-center bu-m-2">
          <input
            type="checkbox"
            id={`${id}-${option.key}`}
            name={id}
            onChange={handleChange}
            disabled={disabled}
            value={option.value}
            checked={selectedValues.includes(option.value)}
            className={`bu-mr-2 ${disabled ? "bu-opacity-50" : ""}`}
          />
          {option.value}
        </label>
      ))}
      {error && (
        <div id={`${id}-error`} className="bu-capitalize-first bu-text-red">
          {error}
        </div>
      )}
    </div>
  );
}
