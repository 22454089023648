// Libraries
import {
  faArrowDown,
  faArrowUp,
  faClone,
  faHeart as faHeartSolid,
  faPen,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import { Block } from "@/lib/blocks";
import { addBlock, deleteBlock, orderDown, orderUp } from "@/redux/slices/editPageSlice";
import { useDispatch } from "react-redux";

interface SectionToolsProps {
  setIsToolEditorOpen: (isOpen: boolean) => void;
  block: Block;
}

export default function SectionTools({ setIsToolEditorOpen, block }: SectionToolsProps) {
  const [activeIcon, setActiveIcon] = useState<string | null>(null);

  const dispatch = useDispatch();

  const handleClick = (name: string) => {
    setActiveIcon(name);
  };

  return (
    <div className="bu-absolute bu-right-0 bu-top-10 bu-z-sticky bu-flex bu-flex-col bu-gap-2 bu-rounded bu-border bu-bg-white bu-p-2">
      <div
        onClick={() => {
          handleClick("edit");
          setIsToolEditorOpen(true);
        }}
        className="bu-flex bu-cursor-pointer bu-items-center bu-gap-3 bu-rounded bu-border bu-p-2.5 bu-text-lightgrey hover:bu-bg-slate-50"
      >
        <FontAwesomeIcon icon={faPen} className="bu-text-xl" />
        <h6 className="bu-text-sm">Edit section</h6>
      </div>

      <div className="bu-flex bu-items-center bu-gap-1 bu-rounded bu-border bu-p-1 bu-text-xl bu-text-darkgrey">
        <FontAwesomeIcon
          icon={faClone}
          onClick={() => dispatch(addBlock(block))}
          className={`bu-cursor-pointer bu-rounded bu-p-2 bu-text-lightgrey hover:bu-bg-slate-50 ${
            activeIcon === "duplicate" ? "bu-bg-white bu-text-darkgrey" : "bu-bg-white"
          }`}
        />
        <FontAwesomeIcon
          icon={faHeartSolid}
          onClick={() => console.warn("favorite")}
          className={`bu-cursor-pointer bu-rounded bu-p-2 bu-text-lightgrey hover:bu-bg-slate-50 ${
            activeIcon === "favorite" ? "bu-bg-white bu-text-darkgrey" : "bu-bg-white"
          }`}
        />
        <FontAwesomeIcon
          icon={faArrowUp}
          onClick={() => dispatch(orderUp(block))}
          className={`bu-cursor-pointer bu-rounded bu-p-2 bu-text-lightgrey hover:bu-bg-slate-50 ${
            activeIcon === "arrowup" ? "bu-bg-white bu-text-darkgrey" : "bu-bg-white"
          }`}
        />
        <FontAwesomeIcon
          icon={faArrowDown}
          onClick={() => dispatch(orderDown(block))}
          className={`bu-cursor-pointer bu-rounded bu-p-2 bu-text-lightgrey hover:bu-bg-slate-50 ${
            activeIcon === "arrowdown" ? "bu-bg-white bu-text-darkgrey" : "bu-bg-white"
          }`}
        />
      </div>
      <div
        onClick={() => dispatch(deleteBlock(block.id))}
        className="bu-flex bu-cursor-pointer bu-items-center bu-gap-3 bu-rounded bu-border bu-p-2.5 bu-text-red hover:bu-bg-slate-50"
      >
        <FontAwesomeIcon icon={faTrashCan} className="bu-text-xl" />
        <h6 className="bu-text-sm">Delete</h6>
      </div>
    </div>
  );
}
