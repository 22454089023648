// Libraries
import Editor from "@/components/page-builder/section-editor/Editor";
import SectionTools from "@/components/page-builder/section-editor/SectionTools";
import { Block, Component } from "@/lib/blocks";
import { useState } from "react";

// Component
export default function Menu({ block, component }: { block: Block; component: Component }) {
  const [showEditView, setEditView] = useState(false);

  return showEditView ? (
    <Editor openState={setEditView} block={block} component={component} />
  ) : (
    <SectionTools setIsToolEditorOpen={setEditView} block={block} />
  );
}
