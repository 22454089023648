import { Block } from "@/lib/blocks";
import { createSlice, current } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

type TEditPageProps = {
  blocks: Block[];
};

const initialState: TEditPageProps = {
  blocks: [],
};

export const editPageSlice = createSlice({
  name: "Edit page blocks",
  initialState,
  reducers: {
    setBlocks: (state, action) => {
      return {
        ...state,
        blocks: action.payload.sort((a: Block, b: Block) => a.order - b.order) || [],
      };
    },

    addBlock: (state, action) => {
      const tempId = uuid();
      const newBlock = {
        id: tempId,
        type: action.payload.type,
        data: action.payload.data,
        order: action.payload.order,
      };

      // if the block being added already has an order number (e.g. a block is being duplicated)
      if (newBlock.order !== undefined) {
        // add the duplicated block under the original block
        newBlock.order++;

        // create a new array of blocks with the duplicated block added
        const blocksWithDuplicatedBlock = [
          ...state.blocks.slice(0, newBlock.order), // Original blocks before the duplicated block
          newBlock, // Duplicated block
          ...state.blocks.slice(newBlock.order), // Original blocks after
        ];

        // update all order numbers
        const blocksWithUpdatedOrder = blocksWithDuplicatedBlock.map((block, index) => ({
          ...block,
          order: index,
        }));

        return {
          ...state,
          blocks: blocksWithUpdatedOrder,
        };
      }

      // Calculate the order number for the last block in the array
      newBlock.order = state.blocks.length > 0 ? state.blocks[state.blocks.length - 1].order + 1 : 0;

      return {
        ...state,
        blocks: [...state.blocks, newBlock],
      };
    },
    updateBlock: (state, action) => {
      const blockID = action.payload[1];
      const value = action.payload[0];
      const blocks = state.blocks.map((block: any) => {
        if (block.id === blockID) {
          return {
            ...block,
            data: value,
          };
        }
        return block;
      });
      return {
        ...current(state),
        blocks,
      };
    },

    orderUp: (state, action) => {
      const currentOrder = action.payload.order;

      if (currentOrder > 0) {
        const nextOrder = currentOrder - 1;

        // copy the block[] state
        const updatedBlocks = [...state.blocks];

        // get the blocks, current block and block above it (previous block)
        const currentBlock = updatedBlocks[updatedBlocks.findIndex((block: Block) => block.order === currentOrder)];
        const nextBlock = updatedBlocks[updatedBlocks.findIndex((block: Block) => block.order === nextOrder)];
        // Update the order values
        currentBlock.order = nextOrder;
        nextBlock.order = currentOrder;
      }
    },
    orderDown: (state, action) => {
      const currentOrder = action.payload.order;

      if (currentOrder < state.blocks.length - 1) {
        const prevOrder = currentOrder + 1;

        // copy the block[] state
        const updatedBlocks = [...state.blocks];

        // get the blocks, current block and block below it (next block)
        const currentBlock = updatedBlocks[updatedBlocks.findIndex((block: Block) => block.order === currentOrder)];
        const prevBlock = updatedBlocks[updatedBlocks.findIndex((block: Block) => block.order === prevOrder)];
        // Update the order values
        currentBlock.order = prevOrder;
        prevBlock.order = currentOrder;
      }
    },
    deleteBlock: (state, action) => {
      const blockID: string = action.payload;

      // Filter out the block to be deleted
      const blocksWithoutDeletedBlock = state.blocks.filter((block: Block) => block.id !== blockID);

      // Update the order of blocks
      const blocksWithUpdatedOrder = blocksWithoutDeletedBlock.map((block, index) => ({
        ...block,
        order: index,
      }));

      return {
        ...state,
        blocks: blocksWithUpdatedOrder,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBlocks, addBlock, orderUp, orderDown, updateBlock, deleteBlock } = editPageSlice.actions;

export default editPageSlice.reducer;
