import { createSlice } from "@reduxjs/toolkit";

interface EditModeProps {
  value: boolean;
  modal: boolean;
}

const initialState: EditModeProps = {
  value: false,
  modal: false,
};

export const editModeSlice = createSlice({
  name: "edit mode",
  initialState,
  reducers: {
    setMode: (state, action) => {
      return {
        ...state,
        value: action.payload,
      };
    },

    editMode: (state) => {
      return {
        ...state,
        value: true,
      };
    },
    homeMode: (state) => {
      return {
        ...state,
        value: false,
      };
    },
    editModalOpen: (state) => {
      return {
        ...state,
        modal: true,
      };
    },
    editModalClosed: (state) => {
      return {
        ...state,
        modal: false,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMode, editMode, homeMode, editModalOpen, editModalClosed } = editModeSlice.actions;

export default editModeSlice.reducer;
