import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Validation
function invalidToggleValue(value: unknown) {
  return typeof value !== "undefined" && typeof value !== "boolean";
}

// Interface / Types
export interface PageEditorProps {
  viewport: "desktop" | "tablet" | "mobile";
  editMode: boolean;
  showStylingMenu: boolean;
}

type TToggle = PayloadAction<undefined | boolean>;

const initialState: PageEditorProps = {
  viewport: "desktop",
  editMode: true,
  showStylingMenu: false,
};

// Redux Slice
export const pageEditorSlice = createSlice({
  name: "Page Editor ",
  initialState,
  reducers: {
    setViewport: (state, action) => {
      if (!["mobile", "tablet", "desktop"].includes(action.payload)) {
        throw new Error("Can't set viewport to something else than mobile, tablet or desktop");
      }
      state.viewport = action.payload;
    },

    toggleEditMode: (state, action: TToggle) => {
      if (invalidToggleValue(action.payload)) {
        throw new Error(` ${typeof action.payload} as boolean`);
      }

      state.editMode = typeof action.payload === "undefined" ? !state.editMode : action.payload;
    },

    toggleStylingMenu: (state, action: TToggle) => {
      if (invalidToggleValue(action.payload)) {
        throw new Error(`Can't set ${typeof action.payload} as boolean`);
      }

      state.showStylingMenu = typeof action.payload === "undefined" ? !state.showStylingMenu : action.payload;
    },
  },
});

// Action creators are generated for each reducer function
export const { setViewport, toggleEditMode, toggleStylingMenu } = pageEditorSlice.actions;

export default pageEditorSlice.reducer;
