import clsx from "clsx";
import { BlockInterface } from "@/lib/blocks";
import BaseComponent from "./BaseComponent";

export function TextMediaComponent({
  title = "Lorem ipsum dolor sit amet.",
  description = "Consectetur adipiscing elit. Sed imperdiet iaculis nulla ut fringilla.",
  image,
  mediaPosition,
}: {
  title?: string;
  description?: string;
  image?: string;
  mediaPosition?: string;
}) {
  return (
    <div className="bu-bg-background bu-grid bu-grid-cols-2 bu-gap-y-10 @7xl/main:bu-gap-x-20 @7xl/main:bu-px-24  @7xl/main:bu-py-30">
      <div
        className={clsx(
          "bu-col-span-full @7xl/main:bu-col-span-1",
          mediaPosition === "left" && "bu-order-1",
          mediaPosition === "right" && "bu-order-2",
        )}
      >
        <figure className="bu-w-full">
          <img
            className="bu-aspect-[16/9] bu-w-full bu-object-cover"
            src={image}
            alt="Beschrijving van de afbeelding"
          />
        </figure>
      </div>
      <div
        className={clsx(
          "bu-col-span-full bu-flex bu-items-center @7xl/main:bu-col-span-1",
          mediaPosition === "left" && "bu-order-2",
          mediaPosition === "right" && "bu-order-1",
        )}
      >
        <div className="bu-flex bu-flex-col bu-gap-5 bu-self-center">
          <h2 className="bu-font-serif bu-text-5xl bu-text-accent">{title}</h2>
          <p className="bu-text-base bu-leading-normal bu-text-foreground">{description}</p>
        </div>
      </div>
    </div>
  );
}

export default function TextMedia({
  block,
  data = {},
  component,
  editing,
}: BlockInterface<{
  title?: string;
  description?: string;
  image?: string;
  mediaPosition?: string;
}>) {
  if (!editing) {
    return (
      <TextMediaComponent
        title={data?.title}
        description={data?.description}
        image={data?.image}
        mediaPosition={data?.mediaPosition}
      />
    );
  }

  return (
    <BaseComponent block={block} component={component}>
      <TextMediaComponent
        title={data?.title}
        description={data?.description}
        image={data?.image}
        mediaPosition={data?.mediaPosition}
      />
    </BaseComponent>
  );
}
