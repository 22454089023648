import { parseOptions } from "../utils";

export default class Rule {
  handle: string;

  values: { [key: string]: any };

  value?: any;

  option?: number | string;

  optionValue?: string;

  parsedOptions?: string[];

  constructor(handle: string, values: { [key: string]: any }, option: number | string = "", optionValue?: string) {
    this.handle = handle;
    this.values = values;
    this.option = option;
    this.optionValue = optionValue;
  }

  validate() {
    // if (!this.values[this.handle]) {
    //   return false;
    // }
    this.value = this.values[this.handle];
    // in this function we can add basic actions before calling process
    // if (!this.value.trim()) {
    //   return false;
    // }
    if (this.value.includes("  ")) {
      return false;
    }
    this.parsedOptions = parseOptions(String(this.option ?? ""));
    return this.process();
  }

  // eslint-disable-next-line class-methods-use-this
  process() {
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  getMessage(name: string) {
    return `field ${name} is not valid`;
  }
}
