import clsx from "clsx";
import { BlockInterface } from "@/lib/blocks";
import BaseComponent from "./BaseComponent";

export function TextComponent({
  title = "Lorem ipsum dolor sit amet",
  subtitle = "Consectetur adipiscing",
  description = "Consectetur adipiscing elit. Sed imperdiet iaculis nulla ut fringilla.",
  backgroundImage,
  textAlign,
}: {
  title?: string;
  subtitle?: string;
  description?: string;
  backgroundImage?: string;
  textAlign?: string;
}) {
  return (
    <div
      style={{
        backgroundImage: `
        url(${backgroundImage})`,
      }}
      className={clsx(
        "bu-bg-background bu-flex bu-h-full bu-w-full bu-items-center bu-bg-cover bu-px-[5vw] bu-py-25",
        textAlign === "left" ? "bu-justify-left" : "bu-justify-center",
      )}
    >
      <div className={clsx(" bu-space-y-6 bu-text-accent", textAlign !== "center" && "bu-text-center")}>
        <div className="bu-space-y-2">
          <h2 className="bu-font-serif bu-text-foreground">{title}</h2>
          <h3>{subtitle}</h3>
        </div>
        <p>{description}</p>
        <a
          href="#"
          className="bu-mt-12 bu-inline-block bu-rounded-full bu-border-2 bu-border-foreground bu-text-accent bu-px-12 bu-py-3"
        >
          Meld je aan
        </a>
      </div>
    </div>
  );
}

export default function Text({
  block,
  data = {},
  component,
  editing,
}: BlockInterface<{
  title?: string;
  subtitle?: string;
  description?: string;
  backgroundImage?: string;
  textAlign?: string;
}>) {
  if (!editing) {
    return (
      <TextComponent
        title={data?.title}
        subtitle={data?.subtitle}
        description={data?.description}
        backgroundImage={data?.backgroundImage}
        textAlign={data?.textAlign}
      />
    );
  }
  return (
    <BaseComponent block={block} component={component}>
      <TextComponent
        title={data?.title}
        subtitle={data?.subtitle}
        description={data?.description}
        backgroundImage={data?.backgroundImage}
        textAlign={data?.textAlign}
      />
    </BaseComponent>
  );
}
