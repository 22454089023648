import clsx from "clsx";
import BaseComponent from "@/components/content/BaseComponent";
import { BlockInterface } from "@/lib/blocks";

export function BasicTextComponent({
  title = "Lorem ipsum dolor sit amet.",
  subtitle = "Consectetur adipiscing elit. Sed imperdiet iaculis nulla ut fringilla.",
}: Readonly<{ title?: string; subtitle?: string }>) {
  return (
    <div
      className={clsx("bu-bg-background bu-flex bu-h-full bu-w-full bu-items-center bu-bg-cover bu-px-[5vw] bu-py-25")}
    >
      <div className="bu-flex bu-flex-col">
        <h1 className="bu-font-bold bu-text-accent">{title}</h1>

        <h2 className="bu-font-bold bu-text-foreground">{subtitle}</h2>
      </div>
    </div>
  );
}

export default function BasicText({
  block,
  data = {},
  component,
  editing,
}: Readonly<
  BlockInterface<{
    subtitle?: string;
    title?: string;
  }>
>) {
  if (!editing) {
    return <BasicTextComponent title={data?.title} subtitle={data?.subtitle} />;
  }

  return (
    <BaseComponent block={block} component={component}>
      <BasicTextComponent title={data?.title} subtitle={data?.subtitle} />
    </BaseComponent>
  );
}
