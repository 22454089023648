import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

interface Item {
  id: string;
  name: string;
}

interface Props {
  items: Item[];
  selectedItem: Item | undefined;
  onChange: (item: Item) => void;
  disabled: boolean;
  label: string;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Selectbox({ items, selectedItem, onChange, label, disabled }: Props) {
  return (
    <Listbox value={selectedItem} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <>
          <Listbox.Label className="bu-block bu-text-sm bu-font-medium bu-leading-6 bu-text-foreground bu-pt-3">
            {label}
          </Listbox.Label>
          <div className="bu-relative bu-mt-2">
            <Listbox.Button className="bu-relative bu-w-full bu-cursor-default bu-rounded-md bu-bg-background bu-py-1.5 bu-pl-3 bu-pr-10 bu-text-left bu-text-gray-900 bu-shadow-sm bu-ring-1 bu-ring-inset bu-ring-gray-300 bu-focus:outline-none bu-focus:ring-2 bu-focus:ring-indigo-600 bu-sm:text-sm bu-sm:leading-6">
              <span className="bu-block bu-truncate bu-text-foreground">{selectedItem?.name}</span>
              <span className="bu-pointer-events-none bu-absolute bu-inset-y-0 bu-right-0 bu-flex bu-items-center bu-pr-2">
                <ChevronUpDownIcon className="bu-h-5 bu-w-5 bu-text-background" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="bu-transition bu-ease-in bu-duration-100"
              leaveFrom="bu-opacity-100"
              leaveTo="bu-opacity-0"
            >
              <Listbox.Options className="bu-absolute bu-z-10 bu-mt-1 bu-max-h-60 bu-w-full bu-overflow-auto bu-rounded-md bu-bg-white bu-py-1 bu-text-base bu-shadow-lg bu-ring-1 bu-ring-black bu-ring-opacity-5 bu-focus:outline-none bu-sm:text-sm">
                {items?.map((item) => (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bu-bg-accent bu-text-foreground" : "bu-text-gray-900",
                        "bu-relative bu-cursor-default bu-select-none bu-py-2 bu-pl-3 bu-pr-9",
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "bu-font-semibold" : "bu-font-normal",
                            "bu-block bu-truncate",
                          )}
                        >
                          {item.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "bu-text-white" : "bu-text-indigo-600",
                              "bu-absolute bu-inset-y-0 bu-right-0 bu-flex bu-items-center bu-pr-4",
                            )}
                          >
                            <CheckIcon className="bu-h-5 bu-w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
