import clsx from "clsx";

export default function Navigation({ selectedLogo }: { selectedLogo: string | undefined }) {
  const menus = [
    ["Het project", "Stadsvilla's", "Locatie", "Duurzaamheid"],
    ["History", "FAQ", "Downloads", "Financieel"],
  ];

  return (
    <nav
      className={clsx(
        "bu-absolute bu-left-0 bu-top-0 bu-grid  bu-w-full bu-place-items-center bu-px-[5vw] bu-py-8 bu-text-white @2xl/main:bu-grid-cols-2 @7xl/main:bu-grid-cols-[1fr_auto_1fr]",
        "bu-from-black/50 bu-via-black/30 bu-to-black/0 before:bu-pointer-events-none before:bu-absolute before:bu-z-10 before:bu-h-full before:bu-w-full before:bu-bg-gradient-to-b before:bu-content-['']",
      )}
    >
      <ul className="bu-z-20 bu-hidden bu-w-full bu-items-center bu-gap-x-8 @7xl/main:bu-flex">
        {menus[0].map((link: string) => (
          <li key={link}>
            <a href="#">{link}</a>
          </li>
        ))}
      </ul>

      <img src={selectedLogo} style={{ mixBlendMode: "screen" }} className="bu-z-20" />

      <div className="bu-z-20 bu-hidden bu-w-full bu-items-center bu-justify-end bu-gap-x-8 @2xl/main:bu-flex">
        <ul className="bu-hidden bu-gap-x-8 @7xl/main:bu-flex">
          {menus[1].map((link: string) => (
            <li key={link}>
              <a href="#">{link}</a>
            </li>
          ))}
        </ul>
        <a
          href="#"
          className="bu-hidden bu-rounded-full bu-border-2 bu-border-accent bu-px-6 bu-py-3 bu-transition-colors hover:bu-opacity-80 @2xl/main:bu-inline"
        >
          Inschrijven
        </a>
      </div>
    </nav>
  );
}
