/* eslint-disable import/prefer-default-export */
import { TForm } from "@/types/form";
import { TField } from "@/types/formField";
import { AxiosInstance } from "axios";

type TFormGetResponse = {
  data: TForm;
  message: string;
  success: boolean;
};

type TFieldGetResponse = {
  data: TField;
  message: string;
  success: boolean;
};

type TSendResponse = {
  data: TField;
  message: string;
  success: boolean;
};

export class FormClient {
  client: AxiosInstance;

  submissionClient: AxiosInstance;

  constructor(client: AxiosInstance, submissionClient: AxiosInstance) {
    this.client = client;
    this.submissionClient = submissionClient;
  }

  getForm(): Promise<TFormGetResponse> {
    return this.client
      .get("/forms")
      .then((response): TFormGetResponse => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getFormField(formId: string): Promise<TFieldGetResponse> {
    return this.client
      .get(`/forms/${formId}`)
      .then((response): TFieldGetResponse => response.data)
      .catch((error: Error) => {
        throw error;
      });
  }

  sendForm(jsonString: string, formData: any): Promise<TSendResponse> {
    return this.submissionClient
      .post(`/forms/${formData?.id}/submission`, jsonString, {
        headers: {
          "Content-Type": "application/json",
          secret: import.meta.env.VITE_API_SECRET,
        },
      })
      .then((response): TSendResponse => response.data)
      .catch((error: Error) => {
        throw error;
      });
  }
}
