import BaseComponent from "@/components/content/BaseComponent";
import { BlockInterface } from "@/lib/blocks";

export function TextDescriptionComponent({
  title = "Lorem ipsum dolor sit amet.",
  description = "Consectetur adipiscing elit. Sed imperdiet iaculis nulla ut fringilla.",
}: {
  title?: string;
  description?: string;
}) {
  return (
    <div className="bu-bg-background bu-grid bu-grid-cols-12 bu-h-full bu-w-full bu-items-center bu-bg-cover bu-py-25">
      <div className="bu-flex bu-flex-col bu-col-span-full @lg:bu-col-start-4 @md:bu-col-span-6">
        <h1 className="bu-font-bold bu-font-serif bu-text-5xl bu-text-accent">{title}</h1>
        <p className="bu-text-foreground">{description}</p>
      </div>
    </div>
  );
}

export default function TextDescription({
  block,
  data = {},
  component,
  editing,
}: BlockInterface<{
  description?: string;
  title?: string;
}>) {
  if (!editing) {
    return <TextDescriptionComponent description={data?.description} title={data?.title} />;
  }

  return (
    <BaseComponent block={block} component={component}>
      <TextDescriptionComponent description={data?.description} title={data?.title} />
    </BaseComponent>
  );
}
