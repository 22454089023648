import Rule from "../Rule";

export default class MaxRule extends Rule {
  // class
  process() {
    return this.value.toString().length <= Number(this.option);
  }

  getMessage(name: string): string {
    return `${name} must be less than ${this.option} characters`;
  }
}
