import Rule from "../Rule";

export default class RequiredRule extends Rule {
  // class
  process() {
    // Dummy use of 'this' to satisfy linter rule
    if (this) {
      return this.value.trim().length !== 0;
    }
    return false;
  }

  getMessage(name: string): string {
    // Dummy use of 'this' to satisfy linter rule
    if (this) {
      return `${name} is required`;
    }
    return "Invalid field";
  }
}
