import { createSlice } from "@reduxjs/toolkit";
import { Block, blocks } from "@/lib/blocks";

function getBlocks() {
  return blocks.map((block) => {
    return {
      type: block.type,
      data: {},
      order: 0,
    };
  });
}

const initialState = getBlocks();

export const fieldSlice = createSlice({
  name: "fields",
  initialState,
  reducers: {
    // setFields: (state, action) => {
    // console.log(state, action);
    // const passedID = action.payload[1];
    // // const type = action.payload[1];
    // const value = action.payload[0];
    //
    //
    // return state.map((block) => {
    //   // @ts-ignore
    //   if (passedID === id) {
    //     return {
    //       ...block,
    //       data: value,
    //       order: 1,
    //     };
    //   }
    //   return block;
    // });
    // },

    // Sets the blocks to have the fields from the DB into the state.
    setInitialFields: (state, action) => {
      const blocksData = action.payload;

      return state.map((block) => {
        const matchingBlock = blocksData.find((dataBlock: Block) => dataBlock.type === block.type);

        if (matchingBlock) {
          return {
            ...block,
            data: matchingBlock.data,
            order: 1,
          };
        }
        return block;
      });
    },
  },
});

export const { setInitialFields } = fieldSlice.actions;
export default fieldSlice.reducer;
