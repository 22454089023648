import clsx from "clsx";
import { useToggle } from "react-use";

interface ToggleProps {
  init?: boolean;
  id: string;
}

export default function Toggle({ init = false, id }: ToggleProps) {
  const [state, toggle] = useToggle(init);

  return (
    <div
      className={clsx(
        "bu-block bu-h-5 bu-w-9 bu-rounded-full bu-p-0.5 bu-transition-colors bu-duration-300 bu-ease-in-out",
        state ? "bu-bg-primary" : "bu-bg-offwhite",
      )}
    >
      <input id={id} type="checkbox" className="bu-sr-only" checked={state} onChange={toggle} />
      <div
        className={clsx(
          "bu-h-4 bu-w-4 bu-rounded-full bu-transition",
          state ? "bu-translate-x-4 bu-bg-white" : "bu-bg-gray-400",
        )}
      />
    </div>
  );
}
