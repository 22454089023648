// Libraries
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { ChangeEvent, Dispatch, useState } from "react";

// Components
import { useDispatch } from "react-redux";
import Field from "@/components/page-builder/section-editor/Field";
import Toggle from "@/components/ui/Toggle";

// Redux
import { Block, Component, Field as FieldType, SelectOptions } from "@/lib/blocks";
import { updateBlock } from "@/redux/slices/editPageSlice";
import FieldRepeater from "@/components/page-builder/FieldRepeater";
import FormSelector from "@/components/page-builder/FormSelector";
import PlotSelector from "../PlotSelector";
import HousetypeRepeater from "../HousetypeRepeater";

interface BlockDataState {
  [key: string]: string | { [key: string]: string };
}

export default function Editor({
  openState,
  block,
  component,
}: {
  openState: Dispatch<boolean>;
  block: Block;
  component: Component;
}) {
  const [selectedMenu, setSelectedMenu] = useState("Content");

  // Change this to also show
  const [contentData, setContentData] = useState<BlockDataState>(block.data as BlockDataState);

  const dispatch = useDispatch();

  const getContentData = (field: FieldType): string | undefined => {
    if (!contentData) return undefined;
    return contentData[field.name] as unknown as string;
  };

  const handleContentChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    // Update local state.
    setContentData({
      ...(contentData as BlockDataState),
      [event.target.name]: event.target.value,
    });

    // Update redux store.
    dispatch(updateBlock([{ ...contentData, [event.target.name]: event.target.value }, block.id]));
  };

  const handleRepeaterChange = (fieldName: string, newData: any) => {
    // Update contentData for the specific field
    setContentData(() => ({
      ...contentData,
      [fieldName]: newData,
    }));
    // Dispatch updateBlock only for the specific field
    dispatch(updateBlock([{ ...contentData, [fieldName]: newData }, block.id]));
  };

  return (
    <div className="bu-absolute bu-right-0 bu-top-10 bu-z-sticky bu-max-w-2xl bu-overflow-hidden bu-rounded bu-bg-white bu-drop-shadow-xl">
      {/* Tab navigation */}
      <nav className="bu-relative bu-text-lightgrey">
        <ul className="bu-z-tooltip bu-flex bu-flex-row bu-gap-4 bu-border-b bu-border-offwhite bu-px-5 bu-pt-4 bu-text-center bu-text-base bu-font-medium">
          {["Content", "Design"].map((item: string) => (
            <li
              key={item}
              onClick={() => setSelectedMenu(item)}
              className={clsx(
                "bu-mb-px bu-cursor-pointer bu-pb-4 bu-transition-all bu-duration-300 bu-ease-in-out",
                selectedMenu === item ? "bu-shadow-[0_2px_0_0_rgba(41,41,41,1)]" : "bu-text-grey",
              )}
            >
              {item}
            </li>
          ))}

          {/* Close button */}
          <li className="bu-ml-auto">
            <FontAwesomeIcon
              icon={faXmark}
              onClick={() => openState(false)}
              className="bu-cursor-pointer bu-rounded bu-p-1 bu-text-lg bu-text-lightgrey hover:bu-bg-slate-50"
            />
          </li>
        </ul>
      </nav>

      {/* Tabs */}
      {/* Tab: Content */}
      {selectedMenu === "Content" && (
        <div className="bu-flex bu-h-60 bu-flex-col bu-gap-3 bu-overflow-auto bu-p-5 bu-text-black">
          {component.fields.map((field: FieldType, index) => {
            const key = `editor-field-${field.type}-${index}`;

            if (field.repeater) {
              return (
                <Field key={key} label={field.label} htmlFor={`${field.name}-${index}`}>
                  {/* If field should be a repeater to add multiple items */}
                  <FieldRepeater field={field} block={block} setData={handleRepeaterChange} />
                </Field>
              );
            }
            if (field.formSelector) {
              return (
                <Field key={key} label={field.label} htmlFor={`${field.name}-${index}`}>
                  {/* If field should be a repeater to add multiple items */}
                  <FormSelector block={block} />
                </Field>
              );
            }
            if (field.plotSelector) {
              return (
                <Field key={key} label={field.label} htmlFor={`${field.name}-${index}`}>
                  {/* If field should be a repeater to add multiple items */}
                  <PlotSelector block={block} />
                </Field>
              );
            }
            if (field.housetypeRepeater) {
              return (
                <Field key={key} label={field.label} htmlFor={`${field.name}-${index}`}>
                  {/* If field should be a repeater to add multiple items */}
                  <HousetypeRepeater field={field} block={block} setData={handleRepeaterChange} />
                </Field>
              );
            }
            return (
              <Field key={key} label={field.label} htmlFor={`${field.name}-${index}`}>
                {/* Condition 1: If field type is "text" and useTextArea is true 
                    this is used for descriptions, or paragraphs
                */}
                {field.type === "text" && field.useTextArea === true && (
                  <textarea
                    className="bu-rounded bu-border bu-border-gray-300 bu-p-2"
                    id={`${field.name}-${index}`}
                    name={field.name}
                    defaultValue={getContentData(field)}
                    onChange={handleContentChange}
                  />
                )}

                {/* Condition 2: If field type is "text" but not useTextArea 
                    this is used for titles or subtitles
                */}
                {field.type === "text" && field.useTextArea !== true && (
                  <input
                    id={`${field.name}-${index}`}
                    type="text"
                    name={field.name}
                    defaultValue={getContentData(field)}
                    className="bu-rounded bu-border bu-border-gray-300 bu-p-2"
                    onChange={handleContentChange}
                  />
                )}

                {/* Condition 3: If field type is "select" 
                    this is used for selecting images
                */}
                {field.type === "select" && (
                  <select onChange={handleContentChange} name={field.name} id={`${field.name}-${index}`}>
                    {field.options?.map((option: SelectOptions) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                )}

                {/* Condition 5: If there is no field */}
                {!field && <p>Input not found</p>}
              </Field>
            );
          })}
        </div>
      )}

      {/* Tab: Design */}
      {selectedMenu === "Design" && (
        <div className="bu-flex bu-h-80 bu-flex-col bu-gap-3 bu-overflow-auto bu-p-5">
          <p>Layout</p>
          <p>Variatie</p>
          <Field label="Full width" htmlFor="fullWidth">
            <Toggle id="fullWidth" />
          </Field>

          <Field label="Use background image" htmlFor="useBackgroundImage">
            <Toggle id="useBackgroundImage" />
          </Field>

          <Field label="Heading Text Color" htmlFor="headingColor">
            <div className="bu-flex bu-flex-col bu-gap-2">
              <input id="headingColor" name="headingColor" type="color" onChange={handleContentChange} />
            </div>
          </Field>

          <Field label="Text Color" htmlFor="textColor">
            <div className="bu-flex bu-flex-col bu-gap-2">
              <input id="textColor" name="textColor" type="color" onChange={handleContentChange} />
            </div>
          </Field>
        </div>
      )}
    </div>
  );
}
