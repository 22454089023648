import { faPlus, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

interface FieldRepeaterProps {
  block: { data?: Record<string, any> };
  field: { name: string; type: string };
  setData: (name: string, data: any) => void;
}

export default function FieldRepeater({ block, field, setData }: FieldRepeaterProps) {
  const [repeaterData, setRepeaterData] = useState(block.data || {});

  const updateData = (newData: Array<Record<string, any>>) => {
    const nonEmptyData = newData.filter((newField: Record<string, any>) =>
      Object.values(newField).some((value) => value !== ""),
    );
    setData(field.name, nonEmptyData);
  };

  const addField = () => {
    const newField = { value: "" };
    setRepeaterData((prevRepeaterData) => ({
      ...prevRepeaterData,
      [field.name]: [...(prevRepeaterData[field.name] || []), newField],
    }));
    updateData([...(repeaterData[field.name] || []), newField]);
  };

  const handleContentChange = (index: number, value: string) => {
    const updatedField = { ...repeaterData[field.name][index], value };
    const updatedItems = [...repeaterData[field.name]];
    updatedItems[index] = updatedField;
    setRepeaterData((prevRepeaterData) => ({
      ...prevRepeaterData,
      [field.name]: updatedItems,
    }));
    updateData(updatedItems);
  };

  const removeField = (index: number) => {
    const updatedData = [...repeaterData[field.name]];
    updatedData.splice(index, 1);
    setRepeaterData((prevRepeaterData) => ({
      ...prevRepeaterData,
      [field.name]: updatedData,
    }));
    updateData(updatedData);
  };

  return (
    <div className="bu-pl-5">
      {repeaterData[field.name]?.map((repeaterItem: any, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="bu-flex bu-gap-2 bu-mb-2.5">
          <input
            type={field.type}
            value={repeaterItem.value || ""}
            className="bu-border bu-rounded bu-p-2"
            onChange={(e) => handleContentChange(index, e.target.value)}
            placeholder="Enter a value"
          />
          <button
            type="button"
            onClick={() => removeField(index)}
            className="bu-bg-red bu-flex bu-items-center bu-text-white bu-rounded bu-p-2"
          >
            <FontAwesomeIcon icon={faXmark} className="bu-p-2" />
          </button>
        </div>
      ))}
      <div className="bu-flex bu-gap-5 bu-py-2.5">
        <button
          type="button"
          onClick={addField}
          className="bu-bg-primary bu-flex bu-items-center bu-text-white bu-rounded bu-py-2 bu-px-5"
        >
          Add
          <FontAwesomeIcon icon={faPlus} className="bu-pl-2" />
        </button>
      </div>
    </div>
  );
}
