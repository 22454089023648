import { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";

import { faLock } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseComponent from "@/components/content/BaseComponent";

import { SquaresPlusIcon } from "@heroicons/react/20/solid";

import { ApiContext } from "@/providers/apiProviders";

import { FormClient } from "@/api/form";
import validate from "@/lib/validation/formValidation";

import { Block } from "@/lib/blocks";

import { useDispatch } from "react-redux";
import { updateBlock } from "@/redux/slices/editPageSlice";

import { TField } from "@/types/formField";

import TextField from "../FormFields/TextField";
import EmailField from "../FormFields/EmailField";
import PhoneField from "../FormFields/PhoneField";
import TextArea from "../FormFields/TextArea";
import AddressField from "../FormFields/AddressField";
import Alert from "../ui/Alert";
import Button from "../ui/Button";
import SelectField from "../FormFields/SelectField";
import FormSelector from "../page-builder/FormSelector";
import RadioButtons from "../FormFields/RadioButtons";
import CheckBoxes from "../FormFields/Checkboxes";
import PreferenceField from "../FormFields/PreferenceField";
import EditableElement from "../EditableElement";

interface Option {
  key: string;
  value: string;
}

interface FormField {
  id: string;
  type: string;
  label: string;
  rules?: string[];
  is_required?: boolean;
  options?: Option[];
}

interface FormProps {
  block: Block;
  formData: any;
  title?: string;
  subtitle?: string;
  isWebsite: boolean;
}

function FormComponent({
  block,
  formData,
  title = "Lorem ipsum dolor sit",
  subtitle = "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
  isWebsite = true,
}: FormProps) {
  const pageId = window.location.href;
  const pageIdName = "page";

  const dispatch = useDispatch();

  const alertRef = useRef<HTMLDivElement>(null);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [formState, setFormState] = useState<Record<string, string | string[]>>({});
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const [idToLabelMap, setIdToLabelMap] = useState<Record<string, string>>({});
  const [idToFieldType, setIdToFieldType] = useState<Record<string, string>>({});

  const [resetFields, setResetFields] = useState(false);

  const { client, submissionClient } = useContext(ApiContext);
  const formClient = new FormClient(client, submissionClient);

  // Automatically scroll to Alert message
  useEffect(() => {
    if (formSubmitted || errorMessages.length) {
      alertRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [formSubmitted, errorMessages]);

  function SendData(jsonString: string) {
    formClient
      .sendForm(jsonString, formData)
      .then(() => {
        setFormSubmitted(true);
        setFormState({});
        setResetFields(!resetFields);

        setTimeout(() => setFormSubmitted(false), 3000);
      })
      .catch((error: Error) => {
        setErrorMessages([...errorMessages, error.message]);
        console.error(error.message);
      });
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isWebsite) return;

    // Initialize an array to collect error messages
    const localErrors: string[] = [];

    // Flag to track if any required field is missing
    let missingRequiredField = false;

    // Loop through form fields to check for required fields that are empty
    formData.form_fields.forEach((field: FormField) => {
      if (field.is_required && (!formState[field.id] || formState[field.id].length === 0)) {
        missingRequiredField = true;
        localErrors.push(`${field.label} is required.`);
        setFormErrors((prevErrors) => ({ ...prevErrors, [field.id]: `${field.label} is required` }));
      }
    });

    // Update the state with any new error messages
    setErrorMessages(localErrors);

    // If there are any missing required fields, prevent form submission
    if (missingRequiredField) {
      return;
    }

    // Check if form has validation errors
    const hasValidationErrors = Object.values(formErrors).some((error) => error !== "");

    // If there are validation errors, prevent form submission
    if (hasValidationErrors) {
      return;
    }

    // Prevent submission if the form is rendered in the page builder
    if (!isWebsite) {
      return;
    }

    // Prepare the submission data
    const submissionData = Object.keys(formState).reduce(
      (acc, id) => {
        const labelName = idToLabelMap[id];
        const fieldtype = idToFieldType[id];
        acc.push({
          value: formState[id],
          // eslint-disable-next-line object-shorthand
          fieldtype: fieldtype,
          label: labelName,
        });
        return acc;
      },
      [] as { label: string; value: string | string[]; fieldtype: string }[],
    );
    const fieldt = submissionData;
    const formElement = e.target as HTMLFormElement;
    const data = { field: fieldt, page: formElement.action };
    const jsonString = JSON.stringify(data);
    SendData(jsonString);
  };

  const handleChange = (
    id: string,
    name: string,
    value: string | string[],
    rules: string[],
    label: string,
    fieldtype: string,
  ) => {
    setFormState((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      let errorMsg: string | null = null;
      const validationResult = validate(name, updatedData, rules);
      if (validationResult.length) {
        errorMsg = validationResult[0].getMessage(label);
      }
      setFormErrors((prevErrorData) => ({ ...prevErrorData, [name]: errorMsg || "" }));

      return updatedData;
    });

    setIdToFieldType((prevMap) => ({ ...prevMap, [id]: fieldtype }));
    setIdToLabelMap((prevMap) => ({ ...prevMap, [id]: label }));
  };

  const handleTitleChange = (value: string) => {
    dispatch(updateBlock([{ ...block.data, title: value }, block.id]));
  };

  const handleSubtitleChange = (value: string) => {
    dispatch(updateBlock([{ ...block.data, subtitle: value }, block.id]));
  };

  const renderField = (field: FormField) => {
    switch (field.type) {
      case "email":
        return (
          <EmailField
            fieldtype={field.type}
            id={field.id}
            isRequired={field.is_required}
            rules={field.rules}
            label={field.label}
            onChange={handleChange}
            disabled={!isWebsite}
            error={formErrors[field.id]}
            value={(formState[field.id] as string) || ""}
          />
        );
      case "tel":
        return (
          <PhoneField
            id={field.id}
            fieldtype={field.type}
            isRequired={field.is_required}
            rules={field.rules}
            label={field.label}
            onChange={handleChange}
            disabled={!isWebsite}
            error={formErrors[field.id]}
            value={(formState[field.id] as string) || ""}
          />
        );
      case "textarea":
        return (
          <TextArea
            id={field.id}
            fieldtype={field.type}
            isRequired={field.is_required}
            rules={field.rules}
            label={field.label}
            onChange={handleChange}
            disabled={!isWebsite}
            error={formErrors[field.id]}
            value={(formState[field.id] as string) || ""}
          />
        );
      case "address":
        return (
          <AddressField
            id={field.id}
            fieldtype={field.type}
            isRequired={field.is_required}
            errors={{
              "street-address": formErrors["street-address"],
              "house-number": formErrors["house-number"],
              city: formErrors.city,
              "postal-code": formErrors["postal-code"],
            }}
            onChange={handleChange}
            disabled={!isWebsite}
            resetTrigger={resetFields}
          />
        );
      case "selector":
        return (
          <SelectField
            id={field.id}
            fieldtype={field.type}
            isRequired={field.is_required}
            rules={field.rules}
            label={field.label}
            options={field.options || []}
            onChange={handleChange}
            disabled={!isWebsite}
            error={formErrors[field.id]}
            value={(formState[field.id] as string) || ""}
          />
        );
      case "radio":
        return (
          <RadioButtons
            id={field.id}
            fieldtype={field.type}
            isRequired={field.is_required}
            rules={field.rules}
            label={field.label}
            options={field.options || []}
            onChange={handleChange}
            disabled={!isWebsite}
            value={(formState[field.id] as string) || ""}
            error={formErrors[field.id]}
          />
        );
      case "checkboxes":
        return (
          <CheckBoxes
            id={field.id}
            fieldtype={field.type}
            isRequired={field.is_required}
            rules={field.rules}
            label={field.label}
            options={field.options || []}
            onChange={handleChange}
            disabled={!isWebsite}
            error={formErrors[field.id]}
            selectedValues={(formState[field.id] as string) || ""}
          />
        );
      case "preference":
        return (
          <PreferenceField
            id={field.id}
            fieldtype={field.type}
            isRequired={field.is_required}
            errors={{
              type: formErrors.type,
              plot: formErrors.plot,
            }}
            label={field.label}
            onChange={handleChange}
            disabled={!isWebsite}
            resetTrigger={resetFields}
          />
        );
      case "birthdate":
        return (
          <TextField
            id={field.id}
            key={field.id}
            fieldtype={field.type}
            isRequired={field.is_required}
            type="date"
            rules={field.rules}
            label={field.label}
            onChange={handleChange}
            disabled={!isWebsite}
            error={formErrors[field.id]}
            value={(formState[field.id] as string) || ""}
          />
        );
      default:
        return (
          <TextField
            id={field.id}
            fieldtype={field.type}
            isRequired={field.is_required}
            type={field.type}
            rules={field.rules}
            label={field.label}
            onChange={handleChange}
            disabled={!isWebsite}
            error={formErrors[field.id]}
            value={(formState[field.id] as string) || ""}
          />
        );
    }
  };

  if (!formData?.form_fields) {
    if (isWebsite) return null;
    return (
      <div className="bu-bg-background bu-text-center bu-p-[5vw]">
        <div className="bu-flex bu-w-full bu-justify-center">
          <SquaresPlusIcon width={35} className="text-gray-400 bu-fill-foreground" />
        </div>
        <h3 className="bu-mt-2 bu-text-sm bu-font-semibold bu-text-accent">Geen formulieren gevonden</h3>
        <p className="bu-mt-1 bu-text-sm bu-text-foreground">Maak in de backoffice een nieuw formulier aan.</p>
        <div className="bu-mt-6">
          <FormSelector block={block} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx("bu-bg-background bu-px-[5vw] bu-py-25 bu-flex bu-flex-col bu-justify-center bu-items-center")}
    >
      <div className="bu-w-full bu-max-w-5xl">
        <div className="bu-mb-4">
          <EditableElement
            // string concatenation for uniqueness, since block.id is not possible for both Editable Elements
            id={`${block.id}title`}
            as="h1"
            value={title}
            onChange={handleTitleChange}
            // bu-w-max to prevent the title to be somehow centere
            className="bu-font-bold bu-text-foreground bu-leading-relaxed bu-w-max"
            isEditable={!isWebsite}
          />

          <EditableElement
            // string concatenation for uniqueness, since block.id is not possible for both Editable Elements
            id={`${block.id}subtitle`}
            as="p"
            // bu-w-max to prevent the title to be somehow centere
            className="bu-text-lg bu-text-accent bu-w-max"
            value={subtitle}
            onChange={handleSubtitleChange}
            isEditable={!isWebsite}
          />
        </div>
        <div ref={alertRef}>
          {formSubmitted && <Alert theme="success">Formulier is succesvol verzonden!</Alert>}
          {errorMessages.map((errorMessage) => (
            <div key={errorMessage} className="bu-mb-2">
              <Alert theme="error">{errorMessage}</Alert>
            </div>
          ))}
        </div>
        <form onSubmit={handleSubmit}>
          <input type="hidden" name={pageIdName} value={pageId} />
          {formData &&
            formData?.form_fields.map((field: FormField) => (
              <div key={field.id} className="bu-my-5">
                {renderField(field)}
              </div>
            ))}
          <div className="bu-py-5">
            <Button type="submit" theme={!isWebsite ? "disabled" : "pageTheme"}>
              {!isWebsite && <FontAwesomeIcon icon={faLock} />}
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

interface BlockProps {
  block: Block;
  data?: {
    id?: string;
    title?: string;
    subtitle?: string;
    formData?: FormField[];
  };
  component: any;
  editing: boolean;
  isWebsite: boolean;
}

export default function Form({ block, data, component, editing, isWebsite }: BlockProps) {
  const { client, submissionClient } = useContext(ApiContext);
  const formClient = new FormClient(client, submissionClient);
  const [formData, setFormData] = useState<TField | null>(null);
  const fetchForm = async () => {
    try {
      if (data && data.id) {
        const res = await formClient.getFormField(data.id);
        setFormData(res.data);
      } else {
        setFormData(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchForm();
  }, [data]);

  if (!editing) {
    return (
      <FormComponent
        block={block}
        title={data?.title}
        subtitle={data?.subtitle}
        formData={formData}
        isWebsite={isWebsite}
      />
    );
  }

  return (
    <BaseComponent block={block} component={component}>
      <FormComponent
        block={block}
        title={data?.title}
        subtitle={data?.subtitle}
        formData={formData}
        isWebsite={isWebsite}
      />
    </BaseComponent>
  );
}
