// Libraries
import { faCheckCircle, faTriangleExclamation, faXmarkCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

// Types & Interfaces
interface ButtonProps {
  theme?: "success" | "error" | "warning";
  onClick?(): void;
  children: React.ReactNode;
  id?: string;
}

// Component
export default function Alert({ theme, children, onClick }: Readonly<ButtonProps>) {
  return (
    // rose and emerald have been chosen since red-50 is being overriden by a custom red.
    <div
      onClick={onClick}
      className={clsx(" bu-rounded-md bu-p-4 ", {
        "bu-bg-yellow-50": theme === "warning",
        "bu-bg-emerald-50": theme === "success",
        "bu-bg-rose-50": theme === "error",
      })}
    >
      <div className="bu-flex">
        <div className="bu-flex-shrink-0">
          {theme === "warning" && (
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              aria-hidden="true"
              className="bu-h-5 bu-w-5 bu-text-yellow-400"
            />
          )}
          {theme === "success" && (
            <FontAwesomeIcon icon={faCheckCircle} aria-hidden="true" className="bu-h-5 bu-w-5 bu-text-emerald-400" />
          )}
          {theme === "error" && (
            <FontAwesomeIcon icon={faXmarkCircle} aria-hidden="true" className="bu-h-5 bu-w-5 bu-text-rose-400" />
          )}
        </div>
        <div className="bu-ml-3">
          {theme === "warning" && <h3 className="bu-text-sm bu-font-medium bu-text-yellow-800">Let op:</h3>}
          {theme === "success" && <h3 className="bu-text-sm bu-font-medium bu-text-emerald-800">Gelukt!</h3>}
          {theme === "error" && <h3 className="bu-text-sm bu-font-medium bu-text-rose-800">Fout:</h3>}
          <div
            className={clsx("bu-mt-2 bu-text-sm", {
              "bu-text-yellow-700": theme === "warning",
              "bu-text-emerald-700": theme === "success",
              "bu-text-rose-700": theme === "error",
            })}
          >
            <p>{children}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
