import BaseComponent from "@/components/content/BaseComponent";

import { Block } from "@/lib/blocks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useDispatch } from "react-redux";
import { updateBlock } from "@/redux/slices/editPageSlice";

import { faEur, faHouseBlank, faRulerTriangle } from "@fortawesome/pro-regular-svg-icons";
import { toEuroFormat } from "@/lib/utils";

import EditableElement from "../EditableElement";

interface FormProps {
  block: Block;
  description?: string;
  housetypes?: Housetype[];
  isWebsite: boolean;
}

function FeaturedHousetypeComponent({
  block,
  description = "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
  housetypes,
  isWebsite = true,
}: FormProps) {
  const dispatch = useDispatch();

  const handleDescriptionChange = (value: string) => {
    dispatch(updateBlock([{ ...block.data, description: value }, block.id]));
  };

  return (
    <div className="bu-bg-background bu-h-full bu-w-full bu-px-[5vw] bu-py-25">
      <EditableElement
        as="h2"
        id={block.id}
        value={description}
        onChange={handleDescriptionChange}
        className="bu-mb-10 bu-text-foreground bu-w-1/2 md:bu-w-1/3 bu-z-50"
        isEditable={!isWebsite}
      />
      {!isWebsite && (!housetypes || housetypes.length < 1) && (
        <div className="bu-flex bu-flex-col bu-mb-4">
          <div className="bu-items-center">
            <p className="bu-text-xl bu-text-accent">
              Geen woningtype geselecteerd, bewerk deze sectie om er enkele toe te voegen.
            </p>
          </div>
        </div>
      )}
      <div className="bu-grid bu-grid-cols-1 md:bu-grid-cols-2 xl:bu-grid-cols-3 bu-gap-x-4 xl:bu-gap-x-5 bu-gap-y-16 xl:bu-gap-y-20 bu-w-full">
        {housetypes?.map((housetype) => (
          <div key={housetype?.housetype?.id} className="bu-flex bu-flex-col">
            <figure className="bu-relative bu-grow-1 bu-min-h-99 bu-mb-1">
              <img
                src="https://images.pexels.com/photos/1571463/pexels-photo-1571463.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                className="bu-w-full bu-h-full bu-object-cover"
              />
              <div className="bu-bg-gradient-to-b bu-from-transparent bu-to-black bu-absolute bu-w-full bu-h-full bu-bottom-0 bu-opacity-75" />
              <figcaption className="bu-absolute bu-bottom-4 bu-mx-auto bu-text-center bu-w-full bu-font-bold bu-text-xl bu-text-foreground bu-capitalize-first">
                {housetype?.housetype?.name}
              </figcaption>
            </figure>
            <ul className="bu-mt-2 bu-block">
              <li className="bu-flex bu-items-center bu-gap-x-2 bu-pb-2 bu-font-medium">
                {housetype.housetype?.min_price && housetype.housetype?.max_price ? (
                  <>
                    <span className="bu-h-5 bu-w-5 bu-flex">
                      <FontAwesomeIcon icon={faEur} className="bu-text-accent" />
                    </span>
                    {toEuroFormat(Math.round(housetype.housetype.min_price / 50000) * 50000).slice(1, -3)}
                    {" - "}
                    {toEuroFormat(Math.round(housetype.housetype.max_price / 50000) * 50000).slice(1, -3)}
                  </>
                ) : null}
              </li>
              <li className="bu-flex bu-items-center bu-gap-x-2 bu-pb-2 bu-font-medium">
                {housetype.housetype?.min_surface && housetype.housetype?.max_surface ? (
                  <>
                    <span className="bu-h-5 bu-w-5 bu-flex">
                      <FontAwesomeIcon icon={faRulerTriangle} className="bu-text-accent" />
                    </span>
                    {Math.round(housetype.housetype.min_surface / 5) * 5}
                    {" - "}
                    {Math.round(housetype.housetype.max_surface / 5) * 5}m<sup>2</sup>
                  </>
                ) : null}
              </li>
              <li className="bu-flex bu-items-center bu-gap-x-2 bu-pb-2 bu-font-medium">
                {housetype?.housetype?.total_plots ? (
                  <>
                    <span className="bu-h-5 bu-w-5 bu-flex">
                      <FontAwesomeIcon icon={faHouseBlank} className="bu-text-accent" />
                    </span>
                    {`${housetype.housetype.total_plots} woningen`}
                  </>
                ) : null}
              </li>
            </ul>
            <p className="bu-text-base bu-text-foreground bu-font-medium bu-pt-3">{housetype?.description}</p>
          </div>
        ))}
      </div>

      <div className="bu-mt-12">
        <a
          href="#"
          className=" bu-font-medium bu-text-lg bu-rounded-full bu-border-2 bu-border-accent bu-bg-accent bu-text-background bu-px-6 bu-py-3 bu-transition-colors hover:bu-opacity-80 @2xl/main:bu-inline"
        >
          Bekijk het woningaanbod
        </a>
      </div>
    </div>
  );
}

interface Housetype {
  description?: string;
  housetype: {
    id: string;
    name: string;
    max_price?: number;
    min_price?: number;
    min_surface?: number;
    max_surface?: number;
    total_plots?: number;
  };
}

interface BlockProps {
  block: Block;
  data?: Partial<{
    description: string;
    housetype: Housetype[];
  }>;
  component: any;
  editing: boolean;
  isWebsite: boolean;
}

export default function FeaturedHousetype({ block, data, component, editing, isWebsite }: BlockProps) {
  if (!editing) {
    return (
      <FeaturedHousetypeComponent
        block={block}
        description={data?.description}
        housetypes={data?.housetype}
        isWebsite={isWebsite}
      />
    );
  }
  return (
    <BaseComponent block={block} component={component}>
      <FeaturedHousetypeComponent
        block={block}
        description={data?.description}
        housetypes={data?.housetype}
        isWebsite={isWebsite}
      />
    </BaseComponent>
  );
}
