import { ReactNode } from "react";

type TFieldProps = {
  children: ReactNode;
  label: string;
  htmlFor: string;
};
export default function Field({ children, label, htmlFor }: TFieldProps) {
  return (
    <div className="bu-border-b bu-border-offwhite bu-py-2.5 bu-flex bu-flex-row bu-justify-between bu-items-center">
      <label htmlFor={htmlFor}>{label}</label>
      {children}
    </div>
  );
}
