import { Block, getComponentForType } from "@/lib/blocks";

interface IDynamicComponent {
  block: Block;
  isEditing?: boolean;
  isWebsite?: boolean;
}

export default function DynamicComponent({ block, isEditing = true, isWebsite = false }: Readonly<IDynamicComponent>) {
  const dynamicComponent = getComponentForType(block.type);

  const Dynamic = dynamicComponent?.component;

  if (!Dynamic) {
    return <div />;
  }

  return (
    <div className="bu-relative">
      <Dynamic editing={isEditing} component={dynamicComponent} block={block} data={block.data} isWebsite={isWebsite} />
    </div>
  );
}
