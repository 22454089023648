import { createElement, useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";

interface Props {
  as?: string;
  className?: string;
  value?: string;
  onChange?: (value: string) => void;
  isEditable?: boolean;
  id?: string;
}

export default function EditableElement({
  as = "p",
  className = "",
  value = "",
  onChange,
  isEditable = true,
  id = "editorjs",
}: Props) {
  const editorRef = useRef<EditorJS | null>(null);

  useEffect(() => {
    if (!editorRef.current) {
      // Initialize the editor only if it hasn't been initialized yet
      const newEditor = new EditorJS({
        holder: id,
        minHeight: 0,
        data: {
          blocks: [
            {
              type: "paragraph",
              data: {
                text: value,
              },
            },
          ],
        },
        onReady: () => {
          if (!isEditable) {
            newEditor.readOnly.toggle();
          }
        },
        onChange: async (api) => {
          if (!isEditable) return;
          try {
            const outputData = await api.saver.save();
            const newValue = outputData.blocks.map((block) => block.data.text).join("<br>");

            if (newValue) {
              onChange?.(newValue);
            }
          } catch (error) {
            console.error("Failed to save EditorJS content:", error);
          }
        },
      });
      editorRef.current = newEditor;
    }
  }, []);

  return createElement(as, { id, className }, null);
}
