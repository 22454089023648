interface Option {
  key: string;
  value: string;
}
interface Props {
  id: string;
  fieldtype: string;
  options: Option[];
  onChange: (id: string, name: string, value: string, rules: any[], label: string, fieldtype: string) => void;
  isRequired?: boolean;
  rules?: any[];
  error?: string;
  label: string;
  disabled: boolean;
  value: string;
}
export default function RadioButtons({
  id,
  fieldtype,
  options,
  onChange,
  isRequired,
  rules = [],
  error,
  label,
  disabled,
  value,
}: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value: eventValue } = event.target;
    onChange(id, name, eventValue, rules, label, fieldtype);
  };
  fieldtype = "RadioButtons";
  if (isRequired) {
    rules = [...rules, "required"];
  }

  return (
    <div className="bu-flex bu-flex-col">
      <label htmlFor={id} className="bu-m-2 bu-text-foreground">
        {label} {isRequired && <span className="bu-text-red">*</span>}
      </label>

      {options.map((option) => (
        <label key={option.value} htmlFor={`${id}-${option.key}`} className="bu-flex bu-items-center bu-m-2">
          <input
            key={option.key}
            type="radio"
            id={`${id}-${option.key}`}
            name={id}
            value={option.value}
            checked={value === option.value}
            onChange={handleChange}
            required={isRequired}
            disabled={disabled}
            className={`bu-mr-2 ${disabled ? "bu-opacity-50" : ""}`}
          />
          {option.value}
        </label>
      ))}

      {error && (
        <div id={`${id}-error`} className="bu-capitalize-first bu-text-red">
          {error}
        </div>
      )}
    </div>
  );
}
