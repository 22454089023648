import { useContext, useEffect, useState } from "react";
import { ApiContext } from "@/providers/apiProviders";
import { useDispatch } from "react-redux";
import { updateBlock } from "@/redux/slices/editPageSlice";
import { PimClient } from "@/api/pim";
import { TPlot } from "@/types/plot";

interface PlotSelectorProps {
  block: any;
}

export default function PlotSelector({ block }: PlotSelectorProps) {
  const [options, setOptions] = useState<TPlot[] | []>([]);
  const [selectedPlotID, setSelectedPlotID] = useState<string | undefined>(block?.data?.plot?.id);

  const { client } = useContext(ApiContext);
  const pimClient = new PimClient(client);

  const dispatch = useDispatch();

  const setData = (data: { plot: TPlot | undefined }) => {
    dispatch(updateBlock([{ ...block.data, plot: data.plot }, block.id]));
  };

  useEffect(() => {
    async function fetchData() {
      const plotRes = await pimClient.getPlots();
      setOptions(plotRes as unknown as TPlot[]);
    }
    fetchData();
  }, []);

  const handleChange = async (plotID: string) => {
    setSelectedPlotID(plotID);
    const selectedPlot = options.find((option: TPlot) => option?.id === plotID);
    setData({ plot: selectedPlot });
  };

  return (
    <div className="bu-pl-5">
      {options && options.length ? (
        <select
          defaultValue={selectedPlotID}
          name="featuredPlot"
          id="featuredPlot"
          onChange={(e) => handleChange(e.target.value)}
          className="bu-rounded bu-px-3 bu-py-1 bu-text-black"
        >
          <option value={undefined}>Kies een bouwnummer</option>
          {options.map((plot: TPlot) => (
            <option key={plot?.id} value={plot?.id}>
              {plot.construction_number}
            </option>
          ))}
        </select>
      ) : (
        <p>Geen kavels gevonden!</p>
      )}
    </div>
  );
}
