import clsx from "clsx";
import { BlockInterface } from "@/lib/blocks";
import BaseComponent from "./BaseComponent";

export type THeroFullImageComponentProps = {
  title?: string;
  heroImage?: string;
  description?: string;
};

export function HeroFullImageComponent({
  title = "Lorem ipsum dolor sit amet",
  heroImage,
  description = "Consectetur adipiscing elit. Sed imperdiet iaculis nulla ut fringilla.",
}: THeroFullImageComponentProps) {
  return (
    <header
      className={clsx(
        "mask bu-relative bu-z-0 bu-h-screen bu-w-full bu-bg-background",
        "after:bu-absolute after:bu-bottom-0 after:bu-block after:bu-h-1/2 after:bu-w-full after:bu-bg-background after:bu-content-['']",
      )}
    >
      <img
        src={heroImage}
        className="bu-pointer-events-none bu-absolute bu-z-behind bu-h-full bu-w-full bu-object-cover"
      />

      {/* Navigation is extracted into a seperate component */}
      {/* https://project.rox.nl/app/tasks/23997369 */}
      {/* <Navigation selectedLogo={heroLogo} /> */}

      <div className="bu-absolute bu-bottom-8 bu-left-1/2 bu-z-10 bu-max-w-xl -bu-translate-x-1/2 bu-text-center">
        <h1 className="text-balance bu-mb-6 bu-font-serif bu-text-6xl bu-font-medium bu-leading-none bu-text-accent">
          {title}
        </h1>
        <p className="bu-text-foreground bu-text-base bu-leading-normal">{description}</p>
        <a
          href="#"
          className="bu-text-accent bu-mt-12 bu-inline-block bu-rounded-full bu-border-2 bu-border-foreground bu-px-6 bu-py-3"
        >
          Ontdek de villa&apos;s
        </a>
      </div>

      <div className="bu-align-center bu-absolute bu-bottom-0 bu-right-[5vw] bu-z-10 bu-hidden bu-origin-bottom-right -bu-translate-y-8 bu-translate-x-[-15px] bu-rotate-90 bu-items-center @2xl/main:bu-inline-flex">
        <span className="bu-font-semibold bu-opacity-30">S C R O L L</span>
        <div className="bu-ml-8 bu-grid bu-h-[30px] bu-w-[50px] bu-place-items-center bu-rounded-full bu-border-2 bu-border-accent">
          <span className="bu-h-[2px] bu-w-[10px] -bu-translate-x-2 bu-rounded-full bu-bg-foreground" />
        </div>
      </div>
    </header>
  );
}

export default function HeroFullImage({
  block,
  data = {},
  component,
  editing,
}: BlockInterface<{
  title?: string;
  heroImage?: string;
  description?: string;
}>) {
  if (!editing) {
    return <HeroFullImageComponent title={data?.title} heroImage={data?.heroImage} description={data?.description} />;
  }
  return (
    <BaseComponent block={block} component={component}>
      <HeroFullImageComponent title={data?.title} heroImage={data?.heroImage} description={data?.description} />
    </BaseComponent>
  );
}
