import { useSelector } from "react-redux";
import { ReactNode } from "react";
import EditComponentMenu from "@/components/page-builder/section-editor/Menu";
import { Block, Component } from "@/lib/blocks";
import { RootState } from "@/redux/store";

interface IBaseComponent {
  children: ReactNode;
  block: Block;
  component: Component;
}

export default function BaseComponent({ children, block, component }: Readonly<IBaseComponent>) {
  const editing = useSelector((state: RootState) => state.pageEditorSlice.editMode);

  return (
    <>
      {editing && (
        <div className="bu-hidden group-hover:bu-block">
          <EditComponentMenu component={component} block={block} />
        </div>
      )}

      <div>{children}</div>
    </>
  );
}
