import { HTMLInputTypeAttribute, ReactElement } from "react";
import Form from "@/components/content/Form";
import FeaturedPlot from "@/components/content/FeaturedPlot";
import FeaturedHousetype from "@/components/content/FeaturedHousetype";
import BasicText from "../components/content/BasicText";
import HeroFullImage from "../components/content/HeroFullImage";
import HeroImageText from "../components/content/HeroImageText";
import Text from "../components/content/Text";
import TextDescription from "../components/content/TextDescription";
import TextMedia from "../components/content/TextMedia";

export interface Component {
  type: string;
  component: ({ block, data, component }: BlockInterface<object>) => ReactElement;
  fields: Field[];
  padding?: string;
}

export interface Field {
  label: string;
  type: HTMLInputTypeAttribute;
  name: string;
  required: boolean;
  options?: SelectOptions[];
  useTextArea?: boolean;
  repeater?: boolean;
  formSelector?: boolean;
  plotSelector?: boolean;
  housetypeRepeater?: boolean;
}

export interface SelectOptions {
  label: string;
  value: string;
}

export interface Block {
  type: string;
  id: string;
  order: number;
  data: object;
}

export type BlockInterface<T> = {
  data: T;
  block: Block;
  component: Component;
  editing: boolean;
  isWebsite: boolean;
};

const blockList: Component[] = [
  {
    type: "basic-text",
    component: BasicText,
    fields: [
      {
        label: "Title",
        name: "title",
        required: true,
        type: "text",
      },
      {
        label: "Subtitle",
        name: "subtitle",
        required: true,
        type: "text",
      },
    ],
  },
  {
    type: "text-description",
    component: TextDescription,
    fields: [
      {
        label: "Title",
        name: "title",
        required: true,
        type: "text",
      },
      {
        label: "Description",
        name: "description",
        required: true,
        type: "text",
        useTextArea: true,
      },
    ],
  },
  {
    type: "hero-full-image",
    component: HeroFullImage,
    fields: [
      {
        label: "Title",
        name: "title",
        required: true,
        type: "text",
      },
      {
        label: "Description",
        name: "description",
        required: true,
        type: "text",
        useTextArea: true,
      },
      {
        label: "Hero Image",
        name: "heroImage",
        required: false,
        type: "select",
        options: [
          {
            label: "Image 1",
            value: "heros/hero1.jpg",
          },
          {
            label: "Image 2",
            value: "heros/hero2.jpg",
          },
          {
            label: "Image 3",
            value: "heros/hero3.jpg",
          },
          {
            label: "Image 4",
            value: "heros/hero4.jpg",
          },
          {
            label: "Image 5",
            value: "heros/hero5.jpg",
          },
          {
            label: "Image 6",
            value: "heros/hero6.jpg",
          },
        ],
      },
      {
        label: "Hero Logo",
        name: "heroLogo",
        required: false,
        type: "select",
        options: [
          {
            label: "Image 1",
            value: "logos/logo1.png",
          },
          {
            label: "Image 2",
            value: "logos/logo2.png",
          },
          {
            label: "Image 3",
            value: "logos/logo3.png",
          },
          {
            label: "Image 4",
            value: "logos/logo4.png",
          },
          {
            label: "Image 5",
            value: "logos/logo5.png",
          },
          {
            label: "Image 6",
            value: "logos/logo6.png",
          },
        ],
      },
    ],
  },
  {
    type: "hero-image-text",
    component: HeroImageText,
    fields: [
      {
        label: "Title",
        name: "title",
        required: true,
        type: "text",
      },
      {
        label: "Description",
        name: "description",
        required: true,
        type: "text",
      },
      {
        label: "Hero Image",
        name: "heroImage",
        required: false,
        type: "select",
        options: [
          {
            label: "Image 1",
            value: "heros/hero1.jpg",
          },
          {
            label: "Image 2",
            value: "heros/hero2.jpg",
          },
          {
            label: "Image 3",
            value: "heros/hero3.jpg",
          },
          {
            label: "Image 4",
            value: "heros/hero4.jpg",
          },
          {
            label: "Image 5",
            value: "heros/hero5.jpg",
          },
          {
            label: "Image 6",
            value: "heros/hero6.jpg",
          },
        ],
      },
      {
        label: "Hero Logo",
        name: "heroLogo",
        required: false,
        type: "select",
        options: [
          {
            label: "Image 1",
            value: "logos/logo1.png",
          },
          {
            label: "Image 2",
            value: "logos/logo2.png",
          },
          {
            label: "Image 3",
            value: "logos/logo3.png",
          },
          {
            label: "Image 4",
            value: "logos/logo4.png",
          },
          {
            label: "Image 5",
            value: "logos/logo5.png",
          },
          {
            label: "Image 6",
            value: "logos/logo6.png",
          },
        ],
      },
    ],
  },
  {
    type: "text-background",
    component: Text,
    fields: [
      {
        label: "Title",
        name: "title",
        required: true,
        type: "text",
      },
      {
        label: "Subtitle",
        name: "subTitle",
        required: true,
        type: "text",
      },
      {
        label: "Description",
        name: "description",
        required: true,
        type: "text",
        useTextArea: true,
      },
      {
        label: "Background Image",
        name: "backgroundImage",
        required: true,
        type: "select",
        options: [
          {
            label: "Image 1",
            value: "footer/bg1.jpg",
          },
          {
            label: "Image 2",
            value: "footer/bg2.jpg",
          },
          {
            label: "Image 3",
            value: "footer/bg3.jpg",
          },
          {
            label: "Image 4",
            value: "footer/bg4.jpg",
          },
          {
            label: "Image 5",
            value: "footer/bg5.jpg",
          },
          {
            label: "Image 6",
            value: "footer/bg6.jpg",
          },
        ],
      },
      {
        label: "Text Alignment",
        name: "textAlign",
        required: true,
        type: "select",
        options: [
          {
            label: "Link",
            value: "left",
          },
          {
            label: "Center",
            value: "center",
          },
        ],
      },
    ],
  },
  {
    type: "text-media",
    component: TextMedia,
    fields: [
      {
        label: "Title",
        name: "title",
        required: true,
        type: "text",
      },
      {
        label: "Description",
        name: "description",
        required: true,
        type: "text",
        useTextArea: true,
      },
      {
        label: "Image",
        name: "image",
        required: true,
        type: "select",
        options: [
          {
            label: "Image 1",
            value: "footer/bg1.jpg",
          },
          {
            label: "Image 2",
            value: "footer/bg2.jpg",
          },
          {
            label: "Image 3",
            value: "footer/bg3.jpg",
          },
          {
            label: "Image 4",
            value: "footer/bg4.jpg",
          },
          {
            label: "Image 5",
            value: "footer/bg5.jpg",
          },
          {
            label: "Image 6",
            value: "footer/bg6.jpg",
          },
        ],
      },
      {
        label: "Media Position",
        name: "mediaPosition",
        required: true,
        type: "select",
        options: [
          {
            label: "Left",
            value: "left",
          },
          {
            label: "Right",
            value: "right",
          },
        ],
      },
    ],
  },
  {
    type: "featured-construction-number",
    component: FeaturedPlot,
    fields: [
      {
        label: "Bouwnummer",
        name: "constructionNumber",
        required: true,
        type: "select",
        plotSelector: true,
      },
      {
        label: "Description",
        name: "description",
        required: false,
        type: "text",
      },
    ],
  },
  {
    type: "featured-housetype",
    component: FeaturedHousetype,
    fields: [
      {
        label: "Description",
        name: "description",
        required: false,
        type: "text",
      },
      {
        label: "Woningtype",
        name: "housetype",
        required: true,
        type: "select",
        housetypeRepeater: true,
      },
    ],
  },
  {
    type: "form",
    component: Form,
    fields: [
      {
        label: "Form",
        name: "form",
        required: true,
        type: "select",
        formSelector: true,
      },
      {
        label: "Title",
        name: "title",
        required: true,
        type: "text",
      },
      {
        label: "Subtitle",
        name: "subtitle",
        required: true,
        type: "text",
      },
    ],
  },
];

export const blocks = blockList.map((block) => ({ ...block, padding: block.padding }));

export const getComponentForType = (type: string): Component | null => {
  return blocks.find((comp: Component) => comp.type === type) || null;
};
