import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";

interface Props {
  id: string;
  fieldtype: string;
  error?: string;
  errors?: { [key: string]: string };
  onChange: (id: string, name: string, value: string, rules: any[], label: string, fieldtype: string) => void;
  isRequired?: boolean;
  disabled: boolean;
  resetTrigger: boolean;
}

export default function AddressField({
  id,
  isRequired,
  errors,
  error,
  onChange,
  disabled,
  resetTrigger,
  fieldtype,
}: Props) {
  const [localState, setLocalState] = useState({
    "street-address": "",
    "house-number": "",
    city: "",
    "postal-code": "",
  });

  useEffect(() => {
    // Reset local state
    setLocalState({
      "street-address": "",
      "house-number": "",
      city: "",
      "postal-code": "",
    });
  }, [resetTrigger]);

  const allFieldRules: { [key: string]: string[] } = {
    "street-address": ["min:3"],
    "house-number": ["min:1"],
    city: ["min:2"],
    "postal-code": ["min:2", "max:7"],
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, label: string) => {
    const { name, value: inputValue, id: inputID } = event.target;
    const fieldRules = allFieldRules[name] || [];

    setLocalState((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));

    onChange(inputID, name, inputValue, fieldRules, label, fieldtype);
    fieldtype = "AddresField";
  };

  return (
    <div>
      <div className="block sm:bu-flex bu-gap-3 bu-relative">
        <div className="bu-flex bu-flex-col bu-w-full sm:bu-w-9/12 bu-relative bu-py-2 bu-mb-5">
          <input
            data-cy="street-address"
            autoComplete="street-address"
            id="street-address"
            name="street-address"
            type="text"
            required={isRequired}
            onChange={(e) => handleChange(e, "Straatnaam")}
            className={`bu-w-full bu-border-b bu-py-1 focus:bu-outline-none focus:bu-border-b-2 bu-bg-background bu-text-accent bu-transition-colors bu-peer ${
              errors && errors["street-address"] ? "bu-border-red bu-bg-red/5 bu-pl-2" : ""
            }`}
            aria-required={isRequired}
            aria-invalid={!!error}
            aria-describedby={error ? `${id}-error` : undefined}
            disabled={disabled}
            value={localState["street-address"]}
          />
          <label
            htmlFor="street-address"
            className={`bu-absolute bu-left-1 bu-transition-all bu-text-foreground bu-cursor-text ${
              localState["street-address"] ? "bu-text-xs bu--top-4" : "bu-top-2"
            } peer-focus:bu-text-xs peer-focus:bu--top-4 peer-focus:bu-text-foreground`}
          >
            Straatnaam {isRequired && <span className="bu-text-red">*</span>}
          </label>
          {errors && errors["street-address"] && (
            <div id={`${id}-street-address-error`} className="bu-text-red bu-flex bu-gap-1">
              <InformationCircleIcon width={16} />
              {errors["street-address"]}
            </div>
          )}
        </div>
        <div className="bu-flex bu-flex-col bu-w-full sm:bu-w-3/12 bu-relative bu-py-2 bu-mb-5">
          <input
            data-cy="house-number"
            id="house-number"
            name="house-number"
            type="text"
            required={isRequired}
            onChange={(e) => handleChange(e, "Huisnummer")}
            className={`bu-w-full bu-border-b bu-py-1 focus:bu-outline-none focus:bu-border-b-2 bu-bg-background bu-text-accent bu-transition-colors bu-peer ${
              errors && errors["house-number"] ? "bu-border-red bu-bg-red/5 bu-pl-2" : ""
            }`}
            aria-required={isRequired}
            aria-invalid={!!error}
            aria-describedby={error ? `${id}-error` : undefined}
            disabled={disabled}
            value={localState["house-number"]}
          />
          <label
            htmlFor="house-number"
            className={`bu-absolute bu-left-1 bu-transition-all bu-text-foreground bu-cursor-text ${
              localState["house-number"] ? "bu-text-xs bu--top-4" : "bu-top-2"
            } peer-focus:bu-text-xs peer-focus:bu--top-4 peer-focus:bu-text-foreground`}
          >
            Huisnummer {isRequired && <span className="bu-text-red">*</span>}
          </label>
          {errors && errors["house-number"] && (
            <div id={`${id}-house-number-error`} className="bu-text-red bu-flex bu-gap-1">
              <InformationCircleIcon width={16} />
              {errors["house-number"]}
            </div>
          )}
        </div>
        {error && (
          <div id={`${id}-error`} className="bu-capitalize-first bu-text-red bu-flex bu-gap-1">
            <InformationCircleIcon width={16} />
            {error}
          </div>
        )}
      </div>
      <div className="block sm:bu-flex bu-gap-3">
        <div className="bu-flex bu-flex-col bu-w-full sm:bu-w-9/12 bu-relative bu-py-2 bu-mb-5 sm:bu-mb-0">
          <input
            data-cy="city"
            autoComplete="city"
            id="city"
            name="city"
            type="text"
            required={isRequired}
            onChange={(e) => handleChange(e, "Woonplaats")}
            className={`bu-w-full bu-border-b bu-py-1 focus:bu-outline-none focus:bu-border-b-2 bu-bg-background bu-text-accent bu-transition-colors bu-peer ${
              errors && errors.city ? "bu-border-red bu-bg-red/5 bu-pl-2" : ""
            }`}
            aria-required={isRequired}
            aria-invalid={!!error}
            aria-describedby={error ? `${id}-error` : undefined}
            disabled={disabled}
            value={localState.city}
          />
          <label
            htmlFor="city"
            className={`bu-absolute bu-left-1 bu-transition-all bu-text-foreground bu-cursor-text ${
              localState.city ? "bu-text-xs bu--top-4" : "bu-top-2"
            } peer-focus:bu-text-xs peer-focus:bu--top-4 peer-focus:bu-text-foreground`}
          >
            Woonplaats {isRequired && <span className="bu-text-red">*</span>}
          </label>
          {errors && errors.city && (
            <div id={`${id}-city-error`} className="bu-text-red bu-flex bu-gap-1">
              <InformationCircleIcon width={16} />
              {errors.city}
            </div>
          )}
        </div>
        <div className="bu-flex bu-flex-col bu-w-full sm:bu-w-3/12 bu-relative bu-py-2">
          <input
            data-cy="postal-code"
            id="postal-code"
            name="postal-code"
            type="text"
            required={isRequired}
            onChange={(e) => handleChange(e, "Postcode")}
            className={`bu-w-full bu-border-b bu-py-1 focus:bu-outline-none focus:bu-border-b-2 bu-bg-background bu-text-accent bu-transition-colors bu-peer ${
              errors && errors["postal-code"] ? "bu-border-red bu-bg-red/5 bu-pl-2" : ""
            }`}
            aria-required={isRequired}
            aria-invalid={!!error}
            aria-describedby={error ? `${id}-error` : undefined}
            disabled={disabled}
            value={localState["postal-code"]}
          />
          <label
            htmlFor="postal-code"
            className={`bu-absolute bu-left-1 bu-transition-all bu-text-foreground bu-cursor-text ${
              localState["postal-code"] ? "bu-text-xs bu--top-4" : "bu-top-2"
            } peer-focus:bu-text-xs peer-focus:bu--top-4 peer-focus:bu-text-foreground`}
          >
            Postcode {isRequired && <span className="bu-text-red">*</span>}
          </label>
          {errors && errors["postal-code"] && (
            <div id={`${id}-postal-code-error`} className="bu-text-red bu-flex bu-gap-1">
              <InformationCircleIcon width={16} />
              {errors["postal-code"]}
            </div>
          )}
        </div>
        {error && (
          <div id={`${id}-error`} className="bu-flex bu-gap-1 bu-capitalize-first bu-text-red">
            <InformationCircleIcon width={16} /> {error}
          </div>
        )}
      </div>
    </div>
  );
}
