import BaseComponent from "@/components/content/BaseComponent";

import { Block } from "@/lib/blocks";

import { TPlot } from "@/types/plot";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEuroSign, faHouse, faSquareParking, faVectorSquare } from "@fortawesome/free-solid-svg-icons";
import { toEuroFormat } from "@/lib/utils";
import { useDispatch } from "react-redux";
import { updateBlock } from "@/redux/slices/editPageSlice";
import { SquaresPlusIcon } from "@heroicons/react/20/solid";

import EditableElement from "../EditableElement";
import PlotSelector from "../page-builder/PlotSelector";

interface FormProps {
  block: Block;
  description?: string;
  plot?: TPlot;
  isWebsite: boolean;
}

function FeaturedPlotComponent({
  block,
  description = "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
  plot,
  isWebsite = true,
}: FormProps) {
  const dispatch = useDispatch();

  const handleDescriptionChange = (value: string) => {
    dispatch(updateBlock([{ ...block.data, description: value }, block.id]));
  };

  return (
    <div className="bu-relative bu-bg-background">
      <div className="bu-relative bu-h-80 bu-overflow-hidden bu-bg-white md:bu-absolute md:bu-left-0 md:bu-h-full md:bu-w-1/3 lg:bu-w-1/2">
        <div className="bu-relative bu-h-full bu-w-full">
          <img
            className="bu-h-full bu-w-full bu-object-cover"
            src="https://images.pexels.com/photos/1571463/pexels-photo-1571463.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt=""
          />
          <div className="bu-absolute bu-inset-0 bu-bg-gradient-to-r bu-from-transparent bu-to-black/80" />
        </div>
      </div>
      <div className="bu-relative bu-mx-auto bu-max-w-7xl bu-py-24 sm:bu-py-32 lg:bu-px-8 lg:bu-py-40">
        <div className="bu-pl-6 bu-pr-6 md:bu-ml-auto md:bu-w-2/3 md:bu-pl-16 lg:bu-w-1/2 lg:bu-pl-24 lg:bu-pr-0 xl:bu-pl-32">
          {!plot && !isWebsite ? (
            <div className="bu-text-center">
              <div className="bu-flex bu-w-full bu-justify-center">
                <SquaresPlusIcon width={35} className="text-gray-400 bu-fill-foreground" />
              </div>
              <h3 className="bu-mt-2 bu-text-base bu-font-semibold bu-text-accent">Geen bouwnummer geselecteerd</h3>
              <p className="bu-mt-1 bu-text-sm bu-text-foreground">
                Selecteer een bouwnummer, of maak in de backoffice een nieuw kavel aan.
              </p>
              <div className="bu-mt-6">
                <PlotSelector block={block} />
              </div>
            </div>
          ) : (
            <>
              <h2 className="bu-text-base bu-font-semibold bu-leading-7 bu-text-accent">Uitgelicht</h2>

              {plot?.construction_number && (
                <p className="bu-mt-2 bu-text-3xl bu-font-bold bu-tracking-tight bu-text-foreground sm:bu-text-4xl">
                  Bouwnr. {plot.construction_number}
                </p>
              )}

              <EditableElement
                id={block.id}
                as="h3"
                value={description}
                onChange={handleDescriptionChange}
                className="bu-my-6 bu-text-base bu-leading-7 bu-text-foreground"
                isEditable={!isWebsite}
              />
              <div className="bu-grid md:bu-grid-cols-2 bu-gap-y-6">
                {plot?.house_type?.name && (
                  <div className="bu-flex bu-items-center bu-gap-x-3">
                    <span className="bu-text-2xl bu-h-12 bu-w-12 bu-border-2 bu-border-foreground bu-aspect-square bu-rounded-xl bu-grid bu-place-items-center">
                      <FontAwesomeIcon icon={faHouse} width={22} className="" />
                    </span>
                    <div>
                      <p className="bu-text-base bu-leading-4">Woningtype:</p>
                      <p className="bu-font-medium bu-text-xl bu-text-accent">{plot.house_type.name}</p>
                    </div>
                  </div>
                )}
                {plot?.plot_surface && (
                  <div className="bu-flex bu-items-center bu-gap-x-3">
                    <span className="bu-text-2xl bu-h-12 bu-w-12 bu-border-2 bu-border-foreground bu-aspect-square bu-rounded-xl bu-grid bu-place-items-center">
                      <FontAwesomeIcon icon={faVectorSquare} width={22} />
                    </span>
                    <div>
                      <p className="bu-text-base bu-leading-4">Woonoppervlakte:</p>
                      <p className="bu-font-medium bu-text-xl bu-text-accent">{plot.plot_surface} m2</p>
                    </div>
                  </div>
                )}
                {plot?.parking_spots !== undefined && (
                  <div className="bu-flex bu-items-center bu-gap-x-3">
                    <span className="bu-text-2xl bu-h-12 bu-w-12 bu-border-2 bu-border-foreground bu-aspect-square bu-rounded-xl bu-grid bu-place-items-center">
                      <FontAwesomeIcon icon={faSquareParking} width={22} />
                    </span>
                    <div>
                      <p className="bu-text-base bu-leading-4">Parkeerplaatsen:</p>
                      <p className="bu-font-medium bu-text-xl bu-text-accent">{plot.parking_spots}</p>
                    </div>
                  </div>
                )}
                {plot?.price && (
                  <div className="bu-flex bu-items-center bu-gap-x-3">
                    <span className="bu-text-2xl bu-h-12 bu-w-12 bu-border-2 bu-border-foreground bu-aspect-square bu-rounded-xl bu-grid bu-place-items-center">
                      <FontAwesomeIcon icon={faEuroSign} width={22} />
                    </span>
                    <div>
                      <p className="bu-text-base bu-leading-4">Prijs:</p>
                      <p className="bu-font-medium bu-text-xl bu-text-accent">{toEuroFormat(plot.price)}</p>
                    </div>
                  </div>
                )}
              </div>
              <div className="bu-mt-12">
                <a
                  href="#"
                  className="bu-hidden bu-font-medium bu-text-lg bu-rounded-full bu-border-2 bu-border-accent bu-bg-accent bu-text-background bu-px-6 bu-py-3 bu-transition-colors hover:bu-opacity-80 @2xl/main:bu-inline"
                >
                  Inschrijven
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

interface BlockProps {
  block: Block;
  data?: {
    description?: string;
    plot?: TPlot;
  };
  component: any;
  editing: boolean;
  isWebsite: boolean;
}

export default function FeaturedPlot({ block, data, component, editing, isWebsite }: BlockProps) {
  if (!editing) {
    return (
      <FeaturedPlotComponent block={block} description={data?.description} plot={data?.plot} isWebsite={isWebsite} />
    );
  }
  return (
    <BaseComponent block={block} component={component}>
      <FeaturedPlotComponent block={block} description={data?.description} plot={data?.plot} isWebsite={isWebsite} />
    </BaseComponent>
  );
}
