import Rule from "../Rule";

export default class RequiredIfRule extends Rule {
  process() {
    // If parsedOptions is not defined or empty, return true
    if (!this.parsedOptions || this.parsedOptions.length === 0) {
      return true;
    }
    // If parsedOptions has 1 item, the field is required if the specified field has any value
    if (this.parsedOptions.length === 1) {
      return this.values[this.parsedOptions[0]] !== undefined && this.value?.trim().length !== 0;
    }
    // If parsedOptions has 2 items, the field is required if the first value equals the second value
    if (this.values[this.parsedOptions[0]] === this.parsedOptions[1]) {
      return this.value?.trim().length !== 0;
    }
    // If none of the above conditions are met, the field is not required
    return true;
  }

  isRequired() {
    if (!this.parsedOptions || this.parsedOptions.length !== 2) {
      return false;
    }
    return this.values[this.parsedOptions[0]] === this.parsedOptions[1];
  }

  getMessage(name: string): string {
    if (!name) {
      throw new Error("Name is not defined");
    }

    // If parsedOptions has 1 item, return a specific message
    if (this.parsedOptions && this.parsedOptions.length === 1) {
      return `${name} is verplicht als ${this.parsedOptions[0]} een waarde heeft`;
    }

    // If parsedOptions has 2 items, return the original message
    return `${name} is verplicht als waarde van ${this.parsedOptions && this.parsedOptions[0]} "${
      this.parsedOptions && this.parsedOptions[1]
    }" is`;
  }
}
