import Rule from "../Rule";

export default class RequiredIfEmptyRule extends Rule {
  process() {
    // If parsedOptions is not defined or empty, return true
    if (!this.parsedOptions || this.parsedOptions.length === 0) {
      return true;
    }
    // If the specified field is empty, the current field is required
    if (this.values[this.parsedOptions[0]] === undefined || this.values[this.parsedOptions[0]].trim().length === 0) {
      return this.value?.trim().length !== 0;
    }
    // If the specified field is not empty, the current field is not required
    return true;
  }

  getMessage(name: string): string {
    if (!name) {
      throw new Error("Name is not defined");
    }
    // Check if parsedOptions is defined and has at least one element
    const option = this.parsedOptions && this.parsedOptions.length > 0 ? this.parsedOptions[0] : "undefined";
    // Return a specific message
    return `${name} is verplicht als ${option} leeg is`;
  }
}
