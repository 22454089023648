import axios, { AxiosInstance } from "axios";
import { createContext } from "react";

export type TApiContextType = {
  client: AxiosInstance;
  submissionClient: AxiosInstance;
};

export type TApiProviderProps = {
  project: string;
  apiUrl: string;
  children: React.ReactNode;
};

export const ApiContext = createContext<TApiContextType>({
  client: axios.create(),
  submissionClient: axios.create(),
});

export default function ApiProvider({ apiUrl, project, children }: TApiProviderProps) {
  const axiosClient = axios.create({
    baseURL: `${apiUrl}/${project}`,
  });

  const axiosSubmissionClient = axios.create({
    baseURL: `${apiUrl}`,
  });
  return (
    <ApiContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ client: axiosClient, submissionClient: axiosSubmissionClient }}
    >
      {children}
    </ApiContext.Provider>
  );
}
