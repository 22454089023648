// Libraries
import clsx from "clsx";

interface ButtonProps {
  theme?: "secondary" | "green" | "red" | "disabled" | "preview" | "pageTheme";
  onClick?(): void;
  children: React.ReactNode;
  id?: string;
  type?: "button" | "submit";
}

export default function Button({ theme, onClick, children, id, type = "button" }: Readonly<ButtonProps>) {
  return (
    <button
      type={type === "button" ? "button" : "submit"}
      id={id}
      className={clsx(
        "bu-inline-flex bu-items-center bu-gap-2 bu-rounded-md bu-px-6 bu-py-3.5 bu-text-sm bu-font-medium bu-transition-colors bu-duration-300",
        {
          "bu-bg-secondary bu-text-white": theme === "secondary",
          "bu-bg-green bu-text-white hover:bu-bg-greenhover": theme === "green",
          "bu-bg-red bu-text-white": theme === "red",
          "bu-bg-lightgrey bu-text-slate-400": theme === "disabled",
          "bu-bg-lightgrey bu-text-white hover:bu-bg-darkgrey": theme === "preview",
          "bu-bg-foreground bu-text-accent hover:bu-bg-accent hover:bu-text-background": theme === "pageTheme",
          "bu-bg-primary bu-text-white hover:bu-bg-secondary": !theme,
        },
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
