import { useContext, useEffect, useState } from "react";
import { PimClient } from "@/api/pim";
import { toEuroFormat } from "@/lib/utils";
import { ApiContext } from "@/providers/apiProviders";
import Selectbox from "../ui/Selectbox";

interface Props {
  id: string;
  fieldtype: string;
  error?: string;
  errors?: { [key: string]: string };
  onChange: (id: string, name: string, value: string, rules: any[], label: string, fieldtype: string) => void;
  isRequired?: boolean;
  label: string;
  disabled: boolean;
  resetTrigger: boolean;
}

interface Plot {
  construction_number: string;
  house_number: string;
  house_type_id: string | null;
  id: string;
  plotStatus: {
    id: string;
    name: string;
    color: string;
  };
  postal_code: string;
  price: number;
  project_id: string;
  updated_at: string;
}

interface HouseType {
  id: string;
  name: string;
}

interface Item {
  id: string;
  name: string;
}

export default function PreferenceField({
  id,
  fieldtype,
  isRequired,
  errors,
  error,
  label,
  onChange,
  disabled,
  resetTrigger,
}: Props) {
  const { client } = useContext(ApiContext);
  const pimClient = new PimClient(client);

  const [houseTypeID, setHouseTypeID] = useState<string>("");
  const [selectedHouseType, setSelectedHouseType] = useState<HouseType | undefined>(undefined);
  const [selectedPlot, setSelectedPlot] = useState<Item | undefined>(undefined);
  const [houseTypes, setHouseTypes] = useState<HouseType[]>([]);
  const [plots, setPlots] = useState<Plot[]>([]);
  const [plotsForHouseType, setPlotsForHouseType] = useState<Plot[]>([]);

  // Fetch house types and plots on component mount
  useEffect(() => {
    async function fetchData() {
      const houseTypeRes = await pimClient.getHouseTypes();
      setHouseTypes(houseTypeRes.data as unknown as HouseType[]);
      const plotRes = await pimClient.getPlots();
      setPlots(plotRes as unknown as Plot[]);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setHouseTypeID("");
  }, [resetTrigger]);

  // Set initial house type and plots for that house type when houseTypes or houseTypeID changes
  useEffect(() => {
    const filteredPlots = plots.filter((plot) => plot.house_type_id === houseTypeID);
    setPlotsForHouseType(filteredPlots);
    if (filteredPlots.length > 0 && !selectedPlot) {
      const initialPlot = filteredPlots[0];
      setSelectedPlot({
        id: initialPlot.id,
        name: `${initialPlot.construction_number} | ${toEuroFormat(initialPlot.price)} | ${
          initialPlot.plotStatus.name
        }`,
      });
    }
  }, [houseTypes, houseTypeID, plots, selectedPlot]);

  // Handlers for changing selections
  const handleHouseTypeChange = (item: Item) => {
    setHouseTypeID(item.id);
    setSelectedHouseType(item);
    // Reset selected plot when house type changes
    setSelectedPlot(undefined);
  };

  const handlePlotChange = (item: Item) => {
    setSelectedPlot(item);
    onChange(id, id, item.name, [], label, fieldtype);
  };
  fieldtype = "PreferenceField";
  return (
    <div>
      <div className="block gap-3 sm:flex">
        <div className="flex flex-col w-full sm:w-1/2">
          <label htmlFor="type" className="">
            {label} {isRequired && <span className="text-red-500">*</span>}
          </label>
          <Selectbox
            items={houseTypes.map((ht) => ({ id: ht.id, name: ht.name }))}
            data-cy="housetype"
            selectedItem={selectedHouseType || { id: "", name: "Maak een keuze" }} // fallback value
            onChange={handleHouseTypeChange}
            label="Woningtype"
            disabled={disabled}
          />
          {errors?.type && (
            <div id={`${id}-type-error`} className="text-red-500">
              {errors.type}
            </div>
          )}
        </div>
        <div className="flex flex-col w-full sm:w-1/2">
          <Selectbox
            items={plotsForHouseType?.map((plot) => ({
              id: plot.id,
              name: `${plot.construction_number} | ${toEuroFormat(plot.price)} | ${plot.plotStatus.name}`,
            }))}
            data-cy="plot"
            selectedItem={selectedPlot || { id: "", name: "Maak een keuze" }} // Provide a fallback value
            onChange={handlePlotChange}
            label="Perceel"
            disabled={disabled}
          />
          {errors?.plot && (
            <div id={`${id}-plot-error`} className="text-red-500">
              {errors.plot}
            </div>
          )}
        </div>
      </div>
      {error && (
        <div id={`${id}-error`} className="text-red-500 capitalize-first">
          {error}
        </div>
      )}
    </div>
  );
}
