import { PimClient } from "@/api/pim";
import { ApiContext } from "@/providers/apiProviders";
import { faPlus, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";

interface HousetypeRepeaterProps {
  block: { data?: Record<string, any> };
  field: { name: string; type: string };
  setData: (name: string, data: any) => void;
}

interface HouseType {
  id: string;
  name: string;
}

export default function HousetypeRepeater({ block, field, setData }: HousetypeRepeaterProps) {
  const [repeaterData, setRepeaterData] = useState(block.data || {});
  const [options, setOptions] = useState<HouseType[]>([]);

  const { client } = useContext(ApiContext);
  const pimClient = new PimClient(client);

  const updateData = (newData: Array<Record<string, any>>) => {
    const nonEmptyData = newData.filter((newField: Record<string, any>) =>
      Object.values(newField).some((value) => value !== ""),
    );
    setData(field.name, nonEmptyData);
  };

  const addField = () => {
    const newField = { housetype: "", description: "" };
    setRepeaterData((prevRepeaterData) => ({
      ...prevRepeaterData,
      [field.name]: [...(prevRepeaterData[field.name] || []), newField],
    }));
    updateData([...(repeaterData[field.name] || []), newField]);
  };

  const handleContentChange = (index: number, value: string, key: string) => {
    let updatedField = { ...repeaterData[field.name][index] };

    if (key === "housetype") {
      const selectedHouseType = options.find((option) => option.id === value);
      updatedField = { ...updatedField, housetype: selectedHouseType };
    } else {
      updatedField = { ...updatedField, [key]: value };
    }

    const updatedItems = [...repeaterData[field.name]];
    updatedItems[index] = updatedField;
    setRepeaterData((prevRepeaterData) => ({
      ...prevRepeaterData,
      [field.name]: updatedItems,
    }));
    updateData(updatedItems);
  };
  const removeField = (index: number) => {
    const updatedData = [...repeaterData[field.name]];
    updatedData.splice(index, 1);
    setRepeaterData((prevRepeaterData) => ({
      ...prevRepeaterData,
      [field.name]: updatedData,
    }));
    updateData(updatedData);
  };

  useEffect(() => {
    async function fetchData() {
      const houseTypeRes = await pimClient.getHouseTypes();
      setOptions(houseTypeRes.data as unknown as HouseType[]);
    }
    fetchData();
  }, []);

  return (
    <div className="bu-text-black">
      {repeaterData[field.name]?.map((repeaterItem: any, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="bu-flex bu-gap-2 bu-mb-2.5">
          <select
            value={repeaterItem.housetype?.id || ""}
            className="bu-border bu-rounded bu-p-2"
            onChange={(e) => handleContentChange(index, e.target.value, "housetype")}
          >
            <option value="">Kies een woningtype</option>
            {options.map((housetype) => (
              <option key={housetype.id} value={housetype.id}>
                {housetype.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            value={repeaterItem.description || ""}
            placeholder="Description"
            className="bu-border bu-rounded bu-p-2"
            onChange={(e) => handleContentChange(index, e.target.value, "description")}
          />
          <button
            type="button"
            onClick={() => removeField(index)}
            className="bu-bg-red bu-flex bu-items-center bu-text-white bu-rounded bu-p-2"
          >
            <FontAwesomeIcon icon={faXmark} className="bu-p-2" />
          </button>
        </div>
      ))}
      <div className="bu-flex bu-gap-5 bu-py-2.5">
        <button
          type="button"
          onClick={addField}
          className="bu-bg-primary bu-flex bu-items-center bu-text-white bu-rounded bu-py-2 bu-px-5"
        >
          Add
          <FontAwesomeIcon icon={faPlus} className="bu-pl-2" />
        </button>
      </div>
    </div>
  );
}
