import { FormClient } from "@/api/form";
import { TForm } from "@/types/form";
import { useContext, useEffect, useState } from "react";
import { ApiContext } from "@/providers/apiProviders";
import { useDispatch } from "react-redux";
import { updateBlock } from "@/redux/slices/editPageSlice";

interface FormSelectorProps {
  block: any;
  onValidityChange?: (isValid: boolean) => void;
}

export default function FormSelector({ block, onValidityChange }: FormSelectorProps) {
  const [options, setOptions] = useState<{ [key: string]: TForm }>({});
  const [selectedForm, setSelectedForm] = useState(block?.data?.id);

  const { client, submissionClient } = useContext(ApiContext);
  const formClient = new FormClient(client, submissionClient);

  const dispatch = useDispatch();

  const setData = (data: object | null) => {
    dispatch(updateBlock([data, block.id]));
  };

  const isValidSelection = () => {
    return selectedForm !== undefined && selectedForm !== null && selectedForm !== "";
  };

  // Gets all forms for the current project
  async function getForms() {
    try {
      const response = await formClient.getForm();
      const forms = response.data;
      if (Array.isArray(forms)) {
        const formattedForms = forms.reduce<{ [key: string]: TForm }>((acc, form) => {
          acc[form.id] = form;
          return acc;
        }, {});
        setOptions(formattedForms);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getForms();
    setSelectedForm(block.data?.id);
  }, []);

  const handleContentChange = async (form: string) => {
    if (!form) {
      setData(null);
      setSelectedForm(null);
      if (onValidityChange) {
        onValidityChange(isValidSelection());
      }

      return;
    }

    setSelectedForm(form);
    let formData;
    try {
      const res = await formClient.getFormField(form);
      formData = res.data;
      setData(formData);
      if (onValidityChange) {
        onValidityChange(isValidSelection());
      }
    } catch (error) {
      console.error(error);
      if (onValidityChange) {
        onValidityChange(isValidSelection());
      }
    }
  };

  return (
    <div className="bu-pl-5">
      <select
        value={selectedForm || ""}
        name="form"
        id="form"
        onChange={(e) => handleContentChange(e.target.value)}
        className="bu-rounded bu-px-3 bu-py-1 bu-text-black"
      >
        <option value="">Maak een keuze</option>
        {options &&
          Object.values(options).map((form: TForm) => (
            <option key={form.id} value={form.id}>
              {form.name}
            </option>
          ))}
      </select>
    </div>
  );
}
