interface Option {
  key: string;
  value: string;
}
interface Props {
  id: string;
  fieldtype: string;
  options: Option[];
  onChange: (id: string, name: string, value: string, rules: any[], label: string, fieldtype: string) => void;
  isRequired?: boolean;
  rules?: any[];
  error?: string;
  label: string;
  disabled: boolean;
  value: string;
}
export default function SelectField({
  id,
  fieldtype,
  options,
  onChange,
  isRequired,
  rules = [],
  error,
  label,
  disabled,
  value,
}: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value: eventValue } = event.target;
    onChange(id, name, eventValue, rules, label, fieldtype);
  };
  fieldtype = "SelectField";
  if (isRequired) {
    rules = [...rules, "required"];
  }

  return (
    <div className="bu-flex bu-flex-col">
      <label htmlFor={id} className="bu-m-2 bu-text-foreground">
        {label} {isRequired && <span className="bu-text-red">*</span>}
      </label>
      <select
        id={id}
        name={id}
        onChange={handleChange}
        required={isRequired}
        disabled={disabled}
        value={value}
        className={`bu-border bu-rounded bu-p-2 bu-text-black ${error ? "bu-border-red" : ""}`}
        aria-required={isRequired}
        aria-invalid={!!error}
        aria-describedby={error ? `${id}-error` : undefined}
      >
        <option id="">Selecteer een optie</option>

        {options?.map((option: { key: string; value: string }) => (
          <option key={option.key} value={option.value}>
            {option.value}
          </option>
        ))}
      </select>
      {error && (
        <div id={`${id}-error`} className="bu-capitalize-first bu-text-red">
          {error}
        </div>
      )}
    </div>
  );
}
