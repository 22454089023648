import Rule from "./Rule";
import EmailRule from "./rules/email";
import MaxRule from "./rules/max";
import MinRule from "./rules/min";
import RequiredRule from "./rules/required";
import RequiredIfRule from "./rules/required_if";
import RequiredIfEmptyRule from "./rules/required_if_empty";
import TelRule from "./rules/tel";

export default function validate(key: string, formState: {}, rules: string[]) {
  const failedRules: Rule[] = [];

  rules.forEach((ruleString) => {
    const ruleParts = ruleString.split(":");
    const ruleHandle = ruleParts[0];
    const ruleValue = ruleParts.length > 1 ? ruleParts[1] : undefined;
    let rule = null;
    switch (ruleHandle) {
      case "required":
        rule = new RequiredRule(key, formState);
        break;

      case "required_if":
        rule = new RequiredIfRule(key, formState, ruleValue);
        break;

      case "required_if_empty":
        rule = new RequiredIfEmptyRule(key, formState, ruleValue);
        break;

      case "min":
        rule = new MinRule(key, formState, ruleValue);
        break;

      case "max":
        rule = new MaxRule(key, formState, ruleValue);
        break;

      case "email":
        rule = new EmailRule(key, formState, ruleValue);
        break;

      case "tel":
        rule = new TelRule(key, formState, ruleValue);
        break;

      default:
        break;
    }

    if (rule && !rule.validate()) {
      failedRules.push(rule);
    }
  });

  return failedRules;
}
