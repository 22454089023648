import axios from "axios";

export default function slugify(str: string): string {
  return str
    .toLowerCase() // convert string to lowercase
    .replace(/^\s+|\s+$/g, "") // trim leading/trailing white space
    .replace(/[^a-z0-9 -]/g, "") // remove any non-alphanumeric characters
    .replace(/\s+/g, "-") // replace spaces with hyphens
    .replace(/-+/g, "-"); // remove consecutive hyphens
}

export function parseOptions(option: string): string[] {
  const parts: string[] = [];
  const ruleValueParts = option.split(",");
  const optionHandle = ruleValueParts.length > 0 ? ruleValueParts[0] : undefined;
  const optionValue = ruleValueParts.length > 1 ? ruleValueParts[1] : undefined;
  if (optionHandle) {
    parts.push(optionHandle);
  }
  if (optionValue) {
    parts.push(optionValue);
  }
  return parts;
}

// Function to check if a URL is external
export function isExternalURL(urlToCheck: string): boolean {
  try {
    const currentOrigin = new URL(window.location.href).origin;
    const newUrl = new URL(urlToCheck, currentOrigin);
    return newUrl.origin !== currentOrigin;
  } catch {
    return false;
  }
}

export function toEuroFormat(price: number) {
  const EURO = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  });
  return EURO.format(price);
}

export async function getPageStyles(apiEndpoint: string) {
  await axios
    .get(apiEndpoint)
    .then((response) => {
      if (response.data.page.styles) {
        document.documentElement.style.setProperty("--colors-background", response.data.page.styles.background);
        document.documentElement.style.setProperty("--colors-foreground", response.data.page.styles.foreground);
        document.documentElement.style.setProperty("--colors-accent", response.data.page.styles.accent);
      }
    })
    .catch((e) => console.error(e));
}
