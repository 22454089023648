import { AxiosInstance } from "axios";

export type TPIM = {
  id: string;
  name: string;
};

type TPIMGetResponse = {
  data: TPIM;
  message: string;
  success: boolean;
};

export class PimClient {
  client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  getHouseTypes(): Promise<TPIMGetResponse> {
    return this.client
      .get("/house-types")
      .then((response): TPIMGetResponse => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getPlots(): Promise<TPIMGetResponse> {
    return this.client
      .get("/plots")
      .then((response): TPIMGetResponse => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
}
