import Rule from "../Rule";

export default class EmailRule extends Rule {
  process() {
    const emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]{2,4}$/;
    return emailReg.test(this.value);
  }

  // eslint-disable-next-line class-methods-use-this
  getMessage(name: string): string {
    return `${name} is not a valid email address`;
  }
}
