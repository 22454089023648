import Navigation from "@/demo/Navigation";
import { BlockInterface } from "@/lib/blocks";
import BaseComponent from "./BaseComponent";

export function HeroImageTextComponent({
  title = "Lorem ipsum dolor sit amet.",
  description = "Consectetur adipiscing elit. Sed imperdiet iaculis nulla ut fringilla.",
  heroImage,
  heroLogo,
}: {
  title?: string;
  description?: string;
  heroImage?: string;
  heroLogo?: string;
}) {
  return (
    <div id="example-component">
      {/* Navigation */}
      <Navigation selectedLogo={heroLogo} />

      {/* Image & Color background */}
      <div className="bu-bg-background bu-grid bu-items-end @7xl/main:bu-h-screen @7xl/main:bu-grid-cols-2">
        {/* Content */}
        <div className="bu-space-y-12 bu-p-12 bu-pt-24 @7xl/main:bu-col-span-1 @7xl/main:bu-p-24">
          <div className="bu-space-y-2">
            <h1 className="bu-text-foreground bu-hidden bu-break-words @7xl/main:bu-block">{title}</h1>
            <h1 className="bu-text-foreground @7xl/main:bu-hidden">{title}</h1>
            <p className="bu-text-accent">{description}</p>
          </div>
          <div>
            <a href="#" className="bu-rounded-full bu-bg-foreground bu-p-4 bu-text-background">
              Ontdek de villa&apos;s
            </a>
          </div>
        </div>
        <div className="bu-h-full @7xl/main:bu-col-span-1">
          {/* Image */}
          <img className="bu-h-screen bu-w-full bu-object-cover" src={heroImage} />
        </div>
      </div>
    </div>
  );
}

export default function HeroImageText({
  block,
  data = {},
  component,
  editing,
}: BlockInterface<{
  title?: string;
  description?: string;
  heroImage?: string;
  heroLogo?: string;
}>) {
  if (!editing) {
    return (
      <HeroImageTextComponent
        heroImage={data?.heroImage}
        heroLogo={data?.heroLogo}
        title={data?.title}
        description={data?.description}
      />
    );
  }

  return (
    <BaseComponent block={block} component={component}>
      <HeroImageTextComponent
        heroImage={data?.heroImage}
        heroLogo={data?.heroLogo}
        title={data?.title}
        description={data?.description}
      />
    </BaseComponent>
  );
}
