// todo: required check hier plaatsen

import Rule from "../Rule";

// export function requiredCheck(input: string | number | boolean) {
//   return input.toString().length === 0;
// }

export default class MinRule extends Rule {
  // class

  process() {
    return this.value.toString().length >= Number(this.option);
  }

  getMessage(name: string): string {
    return `${name} must be at least ${this.option} characters`;
  }
}
